import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchEmployees } from "../../store/slices/EmployeeSlice";

const AddAssetModelPopup = ({ onSubmit, onEdit, editAsset, mode }) => {
  const [name, setName] = useState("");
  const [assetId, setAssetId] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [warranty, setWarranty] = useState("");
  const [amount, setAmount] = useState("");
  const [assignedUser, setAssignedUser] = useState(null);
  const [status, setStatus] = useState("Available");
  const [specs, setSpecs] = useState({
    os: "",
    ram: "",
    ssd: "",
    hdd: "",
    processor: "",
  });
  const [accessories, setAccessories] = useState([
    { name: "", type: "", serialNumber: "" },
  ]); // Initialize accessory list with valid fields

  const accessoryOptions = [
    { value: "Mouse", label: "Mouse" },
    { value: "Headset", label: "Headset" },
    { value: "Keyboard", label: "Keyboard" },
    { value: "Charger", label: "Charger" },
  ];

  const dispatch = useDispatch();
  const users = useSelector((state) => state?.employee?.employees || []);

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  useEffect(() => {
    if (mode === "edit" && editAsset) {
      setName(editAsset.name);
      setAssetId(editAsset.assetId);
      setPurchaseDate(
        new Date(editAsset?.purchaseDate).toISOString().slice(0, 10)
      );
      setWarranty(editAsset?.warranty || "");
      setAmount(editAsset?.amount || "");
      setAssignedUser(
        editAsset?.assignedUser
          ? {
              value: editAsset.assignedUser?._id,
              label: `${editAsset?.assignedUser?.firstName || "No FirstName"} ${
                editAsset?.assignedUser?.lastName || "No LastName"
              }`,
            }
          : null
      );
      setStatus(editAsset.status || "Available");
      setSpecs(
        editAsset.specs || {
          os: "",
          ram: "",
          ssd: "",
          hdd: "",
          processor: "",
        }
      );
      setAccessories(
        editAsset.accessories || [{ name: "", type: "", serialNumber: "" }]
      );
    } else {
      resetForm();
    }
  }, [mode, editAsset]);

  const resetForm = () => {
    setName("");
    setAssetId("");
    setPurchaseDate("");
    setWarranty("");
    setAmount("");
    setAssignedUser(null);
    setStatus("Available");
    setSpecs({
      os: "",
      ram: "",
      ssd: "",
      hdd: "",
      processor: "",
    });
    setAccessories([{ name: "", type: "", serialNumber: "" }]);
  };

  const handleAccessoryChange = (index, field, value) => {
    const updatedAccessories = accessories.map((accessory, i) =>
      i === index ? { ...accessory, [field]: value } : accessory
    );
    setAccessories(updatedAccessories);
  };

  const addAccessory = () => {
    setAccessories([...accessories, { name: "", type: "", serialNumber: "" }]);
  };

  const removeAccessory = (index) => {
    const updatedAccessories = [...accessories];
    updatedAccessories.splice(index, 1);
    setAccessories(updatedAccessories);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name,
      assetId,
      purchaseDate,
      warranty,
      amount,
      assignedUser: assignedUser?.value,
      status,
      specs,
      accessories, // Send valid accessory objects
    };

    try {
      if (mode === "edit") {
        await onEdit({ id: editAsset?._id, assetData: payload });
      } else {
        await onSubmit(payload);
      }
      resetForm();
      closeModal();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to save asset";
      toast.error(errorMessage);
    }
  };

  const closeModal = () => {
    const modalId = "edit_asset";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement?.querySelector(".btn-close");
    if (closeButton) closeButton.click();
  };

  // Filter out already selected accessory types for each dropdown
  const getAvailableAccessoryOptions = (index) => {
    const selectedTypes = accessories
      .map((acc, i) => (i !== index ? acc.type : null))
      .filter(Boolean);
    return accessoryOptions.filter(
      (option) => !selectedTypes.includes(option.value)
    );
  };

  const userOptions = users?.map((user) => ({
    value: user?._id,
    label: `${user?.firstName || "No FirstName"} ${
      user?.lastName || "No LastName"
    }`,
  }));

  return (
    <div id="edit_asset" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          maxWidth: window.innerWidth < 1024 ? "100%" : "40%",
          margin: "0 auto",
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "edit" ? "Edit Asset" : "Add Asset"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Asset Name <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Asset ID <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={assetId}
                  onChange={(e) => setAssetId(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Purchase Date <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={purchaseDate}
                  onChange={(e) => setPurchaseDate(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Warranty (Months) <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={warranty}
                  onChange={(e) => setWarranty(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Amount <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label className="col-form-label">Assign User</label>
                <Select
                  options={userOptions}
                  value={assignedUser}
                  onChange={(option) => setAssignedUser(option)}
                  isClearable
                  placeholder="Select User"
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Status</label>
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Available">Available</option>
                  <option value="Assigned">Assigned</option>
                  <option value="In Repair">In Repair</option>
                  <option value="Retired">Retired</option>
                </select>
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Specs</label>
                <div className="mt-2">
                  <label className="col-form-label">
                    OS <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={specs.os}
                    onChange={(e) => setSpecs({ ...specs, os: e.target.value })}
                  />
                </div>
                <div className="mt-2">
                  <label className="col-form-label">
                    RAM <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={specs.ram}
                    onChange={(e) =>
                      setSpecs({ ...specs, ram: e.target.value })
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className="col-form-label">
                    SSD <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={specs.ssd}
                    onChange={(e) =>
                      setSpecs({ ...specs, ssd: e.target.value })
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className="col-form-label">
                    HDD <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={specs.hdd}
                    onChange={(e) =>
                      setSpecs({ ...specs, hdd: e.target.value })
                    }
                  />
                </div>
                <div className="mt-2">
                  <label className="col-form-label">
                    Processor <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    value={specs.processor}
                    onChange={(e) =>
                      setSpecs({ ...specs, processor: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="input-block mb-3">
                {/* <label className="col-form-label">Accessories</label> */}
                {accessories.map((accessory, index) => (
                  <div key={index} className="mb-2 p-2 border rounded">
                    {" "}
                    {/* Add some spacing and border for better separation */}
                    <div className="row">
                      <div className="col-md-3">
                        <label>Name</label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={accessory.name}
                          onChange={(e) =>
                            handleAccessoryChange(index, "name", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Type</label>
                        <Select
                          options={getAvailableAccessoryOptions(index)}
                          value={accessoryOptions.find(
                            (option) => option.value === accessory.type
                          )}
                          onChange={(option) =>
                            handleAccessoryChange(index, "type", option.value)
                          } 
                          placeholder="Select Type"
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: "44px",
                              minHeight: "44px",
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }),
                          }}
                        />
                      </div>
                      <div className="col-md-2">
                        <label>Sr.#</label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={accessory.serialNumber}
                          onChange={(e) =>
                            handleAccessoryChange(
                              index,
                              "serialNumber",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-2 d-flex ">
                        <button
                          type="button"
                          className="btn text-danger"
                          onClick={() => removeAccessory(index)}
                        >
                          <i className="fa fa-times"></i> {/* Cross icon */}
                          
                        </button>
                      </div>
                    </div>
                  
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addAccessory}
                >
                  Add Accessory
                </button>
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssetModelPopup;

import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLeavesByUserId,
  createLeave,
  updateLeave,
  deleteLeave,
} from "../../../store/slices/LeaveSlice";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeLeaveModelPopup from "../../../components/modelpopup/EmployeeLeaveModelPopup";
import Loading from "../../../hooks/utils/Loading/Loading";

const EmployeeLeave = () => {
  const dispatch = useDispatch();
  const leaves = useSelector((state) => state.leave.leaves);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const isLoading = useSelector( 
    (state) => state.leave.status.fetchByUserId === "loading"
  );
useSelector((state) => state.leave.error.fetchByUserId);
const [showAddLeaveModal, setShowAddLeaveModal] = useState(false);
const [showDeleteModal, setShowDeleteModal] = useState(false);
const [selectedLeave, setSelectedLeave] = useState(null);
const [currentPage, setCurrentPage] = useState(1);
const [pageSize, setPageSize] = useState(10);
useEffect(() => {
  if (userInfo && userInfo.id) {
    dispatch(fetchLeavesByUserId(userInfo.id));
  }
}, [dispatch, userInfo]);

const handleAddLeave = () => {
  setShowAddLeaveModal(true);
  setSelectedLeave(null);
};

// const handleEditLeave = (leave) => {
//   setShowAddLeaveModal(true);
//   setSelectedLeave(leave);
// };
// const handleDeleteLeave = (id) => {
//   setSelectedLeave(id);
//   setShowDeleteModal(true);
// };

  const confirmDeleteLeave = () => {
    if (selectedLeave) {
      dispatch(deleteLeave(selectedLeave));
      setShowDeleteModal(false); // Close the modal after deletion
      setSelectedLeave(null);
      dispatch(fetchLeavesByUserId(userInfo.id)); // Refresh leaves
    }
  };
  

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
    },
    {
      title: "From",
      dataIndex: "startDate",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "To",
      dataIndex: "endDate",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action text-end">
    //       <a
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a
    //           className="dropdown-item"
    //           onClick={() => handleEditLeave(record)}
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           onClick={() => handleDeleteLeave(record._id)}
    //         >
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leaves"
            title="Main"
            subtitle="My Leaves"
            modalClass="d-none col-auto float-end ms-auto"
          />

          <div className="row mb-3">
            <div className="col-md-3">
              <button className="btn btn-primary" onClick={handleAddLeave}>
                Add Leave
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive mb-3">
                <Table
                  columns={columns}
                  dataSource={leaves}
                  rowKey={(record) => record._id}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: leaves.length,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                  }}
                  locale={{
                    emptyText: isLoading ? (
                      <div>
                        <Loading />
                      </div>
                    ) : (
                      "No Data"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add/Edit Leave Modal */}
      {showAddLeaveModal && (
        <EmployeeLeaveModelPopup
          leave={selectedLeave}
          closeModal={() => setShowAddLeaveModal(false)}
          onSubmit={async (data) => {
            if (selectedLeave) {
              await dispatch(
                updateLeave({ id: selectedLeave._id, leaveData: data })
              );
            } else {
              await dispatch(createLeave(data));
            }

            // Fetch updated leaves after creating/updating
            dispatch(fetchLeavesByUserId(userInfo.id));
            setShowAddLeaveModal(false);
          }}
        />
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal} // Pass the state for conditional rendering
          onDeleteLeave={confirmDeleteLeave} // Delete action
          closeModal={() => setShowDeleteModal(false)} // Close modal action
        />
      )}
    </>
  );
};

export default EmployeeLeave;

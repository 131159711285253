import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import useFetch from "../../../../../hooks/api/fetchHook";
import { useSelector, useDispatch } from "react-redux";
import { createCall, updateCall } from "../../../../../store/slices/CallSlice";

const AddEditPage = () => {
  const location = useLocation();
  const [id, setId] = useState(null);

  const [formData, setFormData] = useState({
    createdAt: new Date().toLocaleDateString("en-GB"),
    primaryContact: "",
    dialer: "",
    type: '',
    reason: '',
    comment: '',
    sales: '',
  });

  const base_url = `${process.env.REACT_APP_BASE_URL}`;
  const [options, setOptions] = useState({
    type: [
      { label: "Transaction", value: "Transaction" },
      { label: "Dead Air", value: "Dead Air" },
      { label: "Exempted", value: "Exempted" }
    ]
  }
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);

  const path = location.pathname;

  const fetchOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idFromQuery = queryParams.get('id');
    setId(idFromQuery);
  }, [location]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${base_url}/api/call/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          });
          let data = await response.json();
          data.data.createdAt = new Date(
            data?.data?.createdAt
          ).toLocaleDateString("en-GB");
          setFormData(data?.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData(); // Fetch data only once when id is available
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { apiData } = useFetch(
    `${base_url}/api/fields`,
    fetchOptions
  );

  const inputData = [
    {
      type: "text",
      status: "inputs",
      label: "Date",
      name: "createdAt",
      disabled: true,
    },
    {
      type: "number",
      status: "inputs",
      label: "Primary Contact",
      name: "primaryContact",
    },
    {
      status: "selectors",
      label: "Dialer",
      name: "dialer",
      optionValue: "Dialer",
    },
    {
      status: "selectors",
      label: "Call Type",
      name: "type",
      optionValue: "type",
      required: true,
    },
    {
      status: "selectors",
      label: "Reason (if Exempted)",
      name: "reason",
      optionValue: "ExemptedCalls",
      required: formData.type === "Exempted",
      disabled: formData.type !== "Exempted",
    },
    {
      type: "text",
      status: "inputs",
      label: "Comments",
      name: "comment",
    },
    {
      type: "text",
      status: "inputs",
      label: "Sales Id",
      name: "sale",
      required: formData.type === "Transaction",
      disabled: formData.type !== "Transaction",
    },
  ];

  useEffect(() => {
    if (apiData?.data ) {
      const filteredOptions = inputData
        .filter((item) => item.status === "selectors" && item.optionValue)
        .map((item) => ({
          name: item.name,
          options: apiData.data
            .filter((field) => field.type === item.optionValue)
            .map((field) => ({ value: field._id, label: field.name })),
        }));

      const updatedOptions = filteredOptions.reduce((acc, curr) => {
        acc[curr.name] = curr.options;
        return acc;
      }, {});

      updatedOptions.type = options.type;

      setOptions((prevOptions) => {
        const optionsChanged = JSON.stringify(prevOptions) !== JSON.stringify(updatedOptions);
        if (optionsChanged) {
          return updatedOptions;
        }
        return prevOptions; // Do not update if nothing has changed
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData?.data]); // Add proper dependencies


  const handleChange = (selectedOption, { name }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption?.label || "", // Update to store label
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalData = {
      id: id,
      callData: formData,
    };

    let result;

    if (path.includes('edit-call')) {
      result = await dispatch(updateCall(finalData));
    }

    if (path.includes('create-call')) {
      result = await dispatch(createCall(finalData));
    }

    if (result?.payload?.status === "success") {
      navigate("/calls");
      toast.success("Call Created Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    } else {
      toast.error(`Call Creation failed: ${result?.payload?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="row">
        <form className="col-sm-12"
          onSubmit={handleSubmit}>
          <div >
            <div>
              <div className="row d-flex">
                {inputData.map((item, index) => (
                  <div key={index} className="col-md-3 col-sm-6">
                    <div className="input-block mb-3">
                      {item.status === "inputs" ? (
                        <div>
                          <label htmlFor={item.name} className="col-form-label">
                            {item.label}
                          </label>
                          <input
                            type={item.type}
                            className="form-control"
                            name={item.name}
                            value={formData[item.name] || ""}
                            onChange={handleInputChange}
                            required={item.required}
                            disabled={item.disabled}
                          />
                        </div>
                      ) : item.status === "selectors" ? (
                        <div>
                          <label
                            htmlFor={item.name}
                            className="pb-1 col-form-label"
                          >
                            {item.label}
                          </label>
                          <div className="custom-select-container">
                            <Select
                              options={options[item.name] || []}
                              value={options[item.name]?.find(
                                (option) => option.label === formData[item.name]
                              )}
                              onChange={(selectedOption) =>
                                handleChange(selectedOption, item)
                              }
                              className="form-control p-0"
                              name={item.name}
                              required={item.required}
                              isDisabled={item.disabled}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  minHeight: "38px",
                                }),
                                menu: (base) => ({
                                  ...base,
                                  width: "100%",
                                }),
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="submit-section">
              <button
                className="btn btn-primary submit-btn"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEditPage;

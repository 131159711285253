import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import useFetch from "../../../../../hooks/api/fetchHook";
import Loading from "../../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../../hooks/utils/Error/Error";
import { deleteSale, updateSale } from "../../../../../store/slices/SaleSlice";
import { fetchAllSales } from "../../../../../store/slices/SaleSlice";
import UsePostData from "../../../../../hooks/api/PostHook";
import UsePutData from "../../../../../hooks/api/PutHook";
import { toast } from "react-toastify";

const EstimatesTable = ({ filter }) => {
  const dispatch = useDispatch();
  const { userToken } = useSelector((state) => state?.auth);
  const { sales: apiData } = useSelector((state) => state?.sales || {});
  const { total } = useSelector((state) => state?.sales || "0");
  const role = useSelector((state) => state?.auth?.userRole);
  const department = useSelector(
    (state) => state?.user?.userDetails?.data?.user?.department
  );
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [editingQualityCommentId, setEditingQualityCommentId] = useState(null);
  const [qualityComment, setQualityComment] = useState({});
  const [fetchSales, setFetchSales] = useState(false);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData: users, isLoading } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/users/?page=1&limit=1000`,
    options
  );

  const { postData: followUp } = UsePostData(
    `${process.env.REACT_APP_BASE_URL}/api/sales/followUp`
  );

  const { putData: updateFollowUp } = UsePutData(
    `${process.env.REACT_APP_BASE_URL}/api/sales/followUp`,
    "PUT"
  );

  useEffect(() => {
    dispatch(fetchAllSales({ page, limit, filter }));
  }, [dispatch, page, limit, filter, fetchSales]);

  const [commentValues, setCommentValues] = useState({ comment: '', status: 'pending', commentId: '', _id: '' });
  const [visibleTooltipId, setVisibleTooltipId] = useState(null);

  const handleEditClick = (key, value) => {
    setCommentValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Handle saving of updated comments
  const handlefollowUpSave = async (
    saleId,
  ) => {
    let result;
    if (commentValues?._id) {
      result = await updateFollowUp({ saleId, commentId: commentValues._id, comment: commentValues.comment, status: commentValues.status });
    } else {
      result = await followUp({ saleId, comment: commentValues.comment, status: commentValues.status });
    }
    if (result) {
      if (result?.status === "success") {
        toast.success("Comment Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          theme: "colored",
        });
        setFetchSales(!fetchSales);
        setVisibleTooltipId(null);
      } else {
        toast.error(`Submission failed: ${result?.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          theme: "colored",
        });
      }
    }
  };

  const isToday = (dateString) => {
    const now = new Date();
    const adjustedNow = now.getHours() < 6 ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1) : now;

    const today6AM = new Date(
      adjustedNow.getFullYear(),
      adjustedNow.getMonth(),
      adjustedNow.getDate(),
      6,
      0,
      0
    );

    const tomorrow6AM = new Date(
      adjustedNow.getFullYear(),
      adjustedNow.getMonth(),
      adjustedNow.getDate() + 1,
      6,
      0,
      0
    );

    const date = new Date(dateString);

    return date >= today6AM && date < tomorrow6AM;
  };


  const handleQualityEditClick = (saleId, currentComment) => {
    setEditingQualityCommentId(saleId);
    setQualityComment((prev) => ({
      ...prev,
      [saleId]: currentComment || "",
    }));
  };

  const handleQualitySave = (saleId, comment) => {
    updateSaleData({ _id: saleId, qualityComments: comment });
    setEditingQualityCommentId(null);
  };

  const handleQualityBlur = () => {
    setQualityComment({});
    setEditingQualityCommentId(null);
  };

  const updateSaleData = async (updatedData) => {
    const formData = new FormData();

    Object.keys(updatedData).forEach((key) => {
      // If the value is an array, you may need to handle it differently
      if (Array.isArray(updatedData[key])) {
        updatedData[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item); // Append each array item with an index
        });
      } else {
        formData.append(key, updatedData[key]); // Append key-value pair
      }
    });

    const response = await dispatch(updateSale(formData));

    if (response?.payload?.status === "success") {
      toast.success("Estimate Created Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
      setFetchSales(!fetchSales);
    } else {
      toast.error(`Submission failed: ${response?.payload?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const { apiData: qualityfields } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/fields/QualityControl`,
    options
  );

  const filteredData = apiData || [];

  const salesElements = filteredData.map((sale, index) => ({
    key: index,
    id: sale._id,
    date: new Date(sale.createdAt).toLocaleDateString() || "N/A",
    saleId: sale.saleId || "N/A",
    salesAssociate: sale.employeeId
      ? `${sale.employeeId.firstName} ${sale.employeeId.lastName}`
      : "N/A",
    accountName: sale.accountName || "N/A",
    servicesAddress: sale.servicesAddress || "N/A",
    billingAddress: sale.billingAddress || "N/A",
    primaryContact: sale.primaryContact || "N/A",
    altContact: sale.altContact || "N/A",
    purposeOfCall: sale.purposeOfCall || "N/A",
    currentProvider: sale.currentProvider || "N/A",
    accountType: sale.accountType || "N/A",
    accountBalance: sale.accountBalance || "N/A",
    pastDue: sale.pastDue || "N/A",
    accountNumber: sale.accountNumber || "N/A",
    securityCode: sale.securityCode || "N/A",
    dueDate: new Date(sale.dueDate).toLocaleDateString() || "N/A",
    nameOnCard: sale.nameOnCard || "N/A",
    cardType: sale.cardType || "N/A",
    cardNo: sale.cardNo || "N/A",
    last4Digits: sale.last4Digits || "N/A",
    expiryCard: sale.expiryCard || "N/A",
    authorizedAmount: sale.authorizedAmount || "N/A",
    dob: new Date(sale.dob).toLocaleDateString() || "N/A",
    ssn: sale.ssn || "N/A",
    paymentProcessor: sale.paymentProcessor || "N/A",
    paidOver: sale.paidOver || "N/A",
    callBack: sale.callBack || "N/A",
    payment: sale.payment || "N/A",
    confirmationNumber: sale.paymentConfirmationNumber || "N/A",
    billingTeamComments: sale.billingTeamComments || "N/A",
    qualityControl: sale.qualityControl || "N/A",
    qualityComments: sale.qualityComments || "N/A",
    assignedTo: sale.assignedTo
      ? `${sale.assignedTo?.firstName} ${sale.assignedTo?.lastName}`
      : "N/A",
    followUp: sale.followUp || "N/A",
    status: sale.status || "N/A",
    receipt: sale.receipt || "",
  }));

  const columns = [
    {
      title: "Sale ID",
      dataIndex: "saleId",
      // fixed: 'left',
      onCell: (record, rowIndex) => ({
        style: {
          backgroundColor: rowIndex % 2 === 0 ? "#f5f5f5" : "#ffffff",
          // zIndex: 1,
          borderRight: "2px solid #d9d9d9",
        },
      }),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Sales Associate",
      dataIndex: "salesAssociate",
    },
    {
      title: "Account Name",
      dataIndex: "accountName",
    },
    {
      title: "Services Address",
      dataIndex: "servicesAddress",
    },
    {
      title: "Billing Address",
      dataIndex: "billingAddress",
    },
    {
      title: "Primary Contact",
      dataIndex: "primaryContact",
    },
    {
      title: "Alt Contact",
      dataIndex: "altContact",
    },
    {
      title: "Purpose of Call",
      dataIndex: "purposeOfCall",
    },
    {
      title: "Current Provider",
      dataIndex: "currentProvider",
    },
    ...(role === "admin"
      ? [
        {
          title: "Account Type",
          dataIndex: "accountType",
        },
        {
          title: "Account Balance",
          dataIndex: "accountBalance",
        },
      ]
      : []),
    {
      title: "Past Due",
      dataIndex: "pastDue",
    },
    ...(role === "admin"
      ? [
        {
          title: "Account Number",
          dataIndex: "accountNumber",
        },
        {
          title: "Security Code",
          dataIndex: "securityCode",
        },
      ]
      : []),
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
    ...(role === "admin"
      ? [
        {
          title: "Name On Card",
          dataIndex: "nameOnCard",
        },
        {
          title: "Card Number",
          dataIndex: "cardNo",
        },
        {
          title: "Card Type",
          dataIndex: "cardType",
        },
      ]
      : []),
    {
      title: "Last 4 Digits",
      dataIndex: "last4Digits",
    },
    ...(role === "admin"
      ? [
        {
          title: "Card Expiry Date",
          dataIndex: "expiryCard",
        },
      ]
      : []),
    {
      title: "Authorized Amount",
      dataIndex: "authorizedAmount",
    },
    {
      title: "DOB",
      dataIndex: "dob",
    },
    {
      title: "SSN",
      dataIndex: "ssn",
    },
    {
      title: "Payment Processor",
      dataIndex: "paymentProcessor",
    },
    {
      title: "Paid Over",
      dataIndex: "paidOver",
    },
    {
      title: "Call Back",
      dataIndex: "callBack",
    },
    {
      title: "Confirmation Number",
      dataIndex: "confirmationNumber",
    },
    {
      title: "Billing Team Comments",
      dataIndex: "billingTeamComments",
      onCell: () => ({
        style: {
          maxWidth: "350px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      }),
      render: (text) => (
        <Tooltip title={text || "N/As"} placement="topLeft">
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Quality Control",
      dataIndex: "qualityControl",
      render: (text, record) => (
        <div className="dropdown action-label text-start">
          <div
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "admin"
                  ? "far fa-dot-circle text-purple"
                  : text === "employee"
                    ? "far fa-dot-circle text-info"
                    : text === "manager"
                      ? "far fa-dot-circle text-success"
                      : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </div>
          {department?.type === "quality" || role === 'admin' ? (
            <div className="dropdown-menu dropdown-menu-right">
              {qualityfields?.data.map((field) => (
                <div
                  className="dropdown-item"
                  onClick={() =>
                    updateSaleData({
                      _id: record.id,
                      qualityControl: field.name,
                    })
                  }
                >
                  <i className="far fa-dot-circle text-success cursor-pointer" />{" "}
                  {field.name}
                </div>
              ))}
            </div>
          ) : (
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-item">{""}</div>
            </div>
          )}
        </div>
      ),
      sorter: (a, b) => {
        return a.qualityControl.localeCompare(b.qualityControl);
      },
    },
    {
      title: "Quality Control Comments",
      dataIndex: "qualityComments",
      onCell: () => ({
        style: {
          maxWidth: "350px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      }),
      render: (text, record) => {
        const saleId = record.id;

        return (
          <Tooltip title={text || "N/A"} placement="topLeft">
            {department?.type === "quality" &&
              editingQualityCommentId === saleId ? (
              <Input
                defaultValue={qualityComment[saleId]}
                onPressEnter={(e) => handleQualitySave(saleId, e.target.value)}
                onBlur={() => handleQualityBlur()}
              />
            ) : (
              <span
                onClick={() =>
                  department?.type === "quality" &&
                  handleQualityEditClick(saleId, text)
                }
                style={{ cursor: "pointer" }}
              >
                {text}
              </span>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Follow up Person",
      dataIndex: "assignedTo",
      render: (text, record) => (
        <div className="dropdown action-label text-start">
          <div
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "admin"
                  ? "far fa-dot-circle text-purple"
                  : text === "employee"
                    ? "far fa-dot-circle text-info"
                    : text === "manager"
                      ? "far fa-dot-circle text-success"
                      : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </div>
          {role === "admin" || role === "manager" || role === "supervisor" ? (
            <div className="dropdown-menu dropdown-menu-right">
              {users?.data?.users
                .filter((user) => user?.department?.type === "followup")
                .map((user) => (
                  <div
                    className="dropdown-item"
                    onClick={() =>
                      updateSaleData({ _id: record.id, assignedTo: user._id })
                    }
                  >
                    <i className="far fa-dot-circle text-success cursor-pointer" />{" "}
                    {user.firstName + " " + user.lastName}
                  </div>
                ))}
            </div>
          ) : (
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-item">{""}</div>
            </div>
          )}
        </div>
      ),
      sorter: (a, b) => {
        return a.qualityControl.localeCompare(b.qualityControl);
      },
    },
    {
      title: "Follow up Comment",
      dataIndex: "followUp",
      key: "followUp",
      render: (followUpArray, record) => {
        const latestComment = followUpArray?.length
          ? isToday(followUpArray[followUpArray.length - 1]?.date)
            ? followUpArray[followUpArray.length - 1]
            : ""
          : "";

        return (
          <div className="d-flex align-items-center justify-content-between gap-2">
            <Button
              onClick={() => {
                setVisibleTooltipId(record.id);
                setCommentValues(latestComment);
              }
              }
            >
              {department?.type === "followup" && role === 'employee' ? 'Comment' : 'View Comments'}
            </Button>
            {visibleTooltipId === record.id && (
              <div>
                <div
                  className="position-fixed top-0 start-0 vw-100 vh-100"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 999,
                  }}
                  onClick={() => setVisibleTooltipId(null)}
                />
                <div
                  className="position-fixed top-50 start-50 translate-middle bg-white border p-3 p-md-4 shadow-lg"
                  style={{
                    zIndex: "1000",
                    maxHeight: "400px",
                    width: "500px",
                    overflowY: "auto",
                  }}
                >
                  <h3 className="text-center mb-3">
                    {department?.type === "followup" && role === "employee"
                      ? "Add Comment"
                      : "Follow Up Comments"}
                  </h3>
                  {department?.type === "followup" && role === "employee" ? (
                    <div>
                      <textarea
                        className="form-control mb-2"
                        defaultValue={commentValues?.comment}
                        onChange={(e) => {
                          handleEditClick('comment', e.target.value)
                        }}
                        placeholder="Add your comment"
                      />
                      <div className="mb-3">
                        <label>Status: </label>
                        <select
                          defaultValue={commentValues?.status}
                          onChange={(e) => {
                            handleEditClick('status', e.target.value)
                          }}
                          className="form-select"
                        >
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </select>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-primary"
                          onClick={(e) => handlefollowUpSave(
                            record.id,
                            latestComment._id,
                            e.target.value,
                            latestComment !== "")}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    followUpArray.length === 0
                      ? "No Previous Comments"
                      : followUpArray.map((commentObj, index) => (
                        <div key={index} className="mb-3">
                          <div className="d-flex gap-4">
                            <strong>
                              {commentObj.assignee?.firstName +
                                " " +
                                commentObj.assignee?.lastName}
                            </strong>
                            <p>
                              {new Date(commentObj.date).toLocaleDateString(
                                "en-GB"
                              )}
                            </p>
                          </div>
                          <div className="mt-1 text-wrap">
                            {commentObj.comment}
                          </div>
                        </div>
                      )))}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Follow up Status",
      dataIndex: "followUp",
      key: "followUp",
      render: (followUpArray, record) => {
        const latestComment = followUpArray?.length
          ? isToday(followUpArray[followUpArray.length - 1]?.date)
            ? followUpArray[followUpArray.length - 1]
            : ""
          : "";

        return (
          <p className={`text-capitalize ${latestComment?.status === 'completed' ? 'text-success' : 'text-danger'}`}>
            {latestComment?.status || 'Pending'}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={
            text === "Accepted"
              ? "badge bg-inverse-success"
              : text === "Declined"
                ? "badge bg-inverse-danger"
                : text === "Sent"
                  ? "badge bg-inverse-info"
                  : text === "Expired"
                    ? "badge bg-inverse-warning"
                    : "badge bg-inverse-default"
          }
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      render: (url) => (
        url ? <a href={url} target="_blank" rel="noreferrer" className="text-danger">Show Image</a>
          : <></>
      )
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {/* <Link
              className="dropdown-item"
              to="#"
              onClick={() => toggleDisable(record.id)}
            >
              {disabledSales.includes(record.id) ? (
                <>
                  <i className="fa fa-check m-r-5" /> Enable
                </>
              ) : (
                <>
                  <i className="fa fa-times m-r-5" /> Disable
                </>
              )}
            </Link> */}
            <Link className="dropdown-item" to={`/edit-sale?id=${record?.id}`}>
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteEstimate(record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];
  const handlePageChange = (page, limit) => {
    setLimit(limit);
    setPage(page);
  };

  const handleDeleteEstimate = (estimate) => {
    setSelectedEstimate(estimate);
  };

  const handleDelete = () => {
    if (selectedEstimate) {
      dispatch(deleteSale(selectedEstimate.id));
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const isError = false;
  if (isError) {
    return <Error404 />;
  }

  // const getRowStyle = (record) => {
  //   return disabledSales.includes(record.id)
  //     ? { backgroundColor: "darkgray" }
  //     : { backgroundColor: "" };
  // };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive mb-3">
          <Table
            columns={columns}
            dataSource={isLoading || isError ? [] : salesElements}
            rowKey={(record) => record.id}
            className="table table-striped custom-table mb-0"
            // rowClassName={(record) => getRowStyle(record)}
            // onRow={(record) => ({
            //   style: getRowStyle(record),
            // })}
            locale={{
              emptyText: isLoading ? (
                <Loading />
              ) : isError ? (
                <Error404 />
              ) : (
                "No Data"
              ),
            }}
            pagination={false}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            current={page}
            pageSize={limit}
            total={total} // Total Sales from Redux
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={["10", "20", "50", "100"]}
            showQuickJumper
            showTotal={(total) => `Total ${total} sales`}
            itemRender={(page, type, originalElement) => {
              if (type === "prev") {
                return <p>Previous</p>;
              }
              if (type === "next") {
                return <p>Next</p>;
              }
              if (type === "jump-prev" || type === "jump-next") {
                return <p>...</p>;
              }
              return originalElement;
            }}
          />
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default EstimatesTable;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = `${process.env.REACT_APP_BASE_URL}`;
function objectToQueryParams(obj) {
  return Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&');
}

// Fetch all calls
export const fetchAllCalls = createAsyncThunk(
  "call/fetchAllCalls",
  async ({page, limit, filter}, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(`${base_url}/api/call/?page=${page}&limit=${limit}&${objectToQueryParams(filter)}`, {
        headers: { Authorization: `Bearer ${token}` },
      }); 
      return {
        calls: response?.data?.data,
        total: response?.data?.total,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch calls by user ID
export const fetchCallsByUserId = createAsyncThunk(
  "call/fetchCallsByUserId",
  async (userId, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.get(`${base_url}/api/call/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Create a new call
export const createCall = createAsyncThunk(
  "call/createCall",
  async ({callData}, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.post(`${base_url}/api/call`, callData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update an call
export const updateCall = createAsyncThunk(
  "call/updateCall",
  async ({id, callData}, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      const response = await axios.patch(
        `${base_url}/api/call/${id}`,
        callData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) { 
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete an call
export const deleteCall = createAsyncThunk(
  "call/deleteCall",
  async (id, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      await axios.delete(`${base_url}/api/call/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch Call Report
export const fetchDailyReport = createAsyncThunk(
  "call/fetchDailyReport",
  async ({startDate, endDate}, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      
      const response = await axios.get(`${base_url}/api/daily-report?startDate=${startDate}&endDate=${endDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      }); 

      return response?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch Call Report
export const fetchUserReport = createAsyncThunk(
  "call/fetchUserReport",
  async ({user, date}, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const token = state.auth.userToken;
      
      const response = await axios.get(`${base_url}/api/user-report?user=${user}&date=${date}`, {
        headers: { Authorization: `Bearer ${token}` },
      }); 

      return response?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


const callSlice = createSlice({
  name: "call",
  initialState: { 
    calls: [],
    total:0,
    dailyReport: [],
    userReport: [],
    status: {
      fetchAllCalls: "idle",
      fetchByUserId: "idle",
      createCall: "idle",
      updateCall: "idle",
      deleteCall: "idle",
      fetchDailyReport: "idle",
      fetchUserReport: "idle",
    },
    error: {
      fetchAllCalls: null,
      fetchByUserId: null,
      createCall: null,
      updateCall: null,
      deleteCall: null,
      fetchDailyReport: null,
      fetchUserReport: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all calls
      .addCase(fetchAllCalls.pending, (state) => {
        state.status.fetchAllCalls = "loading";
      })
      .addCase(fetchAllCalls.fulfilled, (state, action) => {
        state.status.fetchAllCalls = "succeeded";
        state.calls = action.payload.calls;
        state.total = action.payload.total; 
      })
      .addCase(fetchAllCalls.rejected, (state, action) => {
        state.status.fetchAllCalls = "failed";
        state.error.fetchAllCalls = action.payload;
      })

      // Fetch calls by user ID
      .addCase(fetchCallsByUserId.pending, (state) => {
        state.status.fetchByUserId = "loading";
      })
      .addCase(fetchCallsByUserId.fulfilled, (state, action) => {
        state.status.fetchByUserId = "succeeded";
        state.calls = action.payload;
      })
      .addCase(fetchCallsByUserId.rejected, (state, action) => {
        state.status.fetchByUserId = "failed";
        state.error.fetchByUserId = action.payload;
      })

      // Create a new call
      .addCase(createCall.pending, (state) => {
        state.status.createCall = "loading";
      })
      .addCase(createCall.fulfilled, (state, action) => {
        state.status.createCall = "succeeded";
        state.calls.push(action.payload);
      })
      .addCase(createCall.rejected, (state, action) => {
        state.status.createCall = "failed";
        state.error.createCall = action.payload;
      })

      // Update an call
      .addCase(updateCall.pending, (state) => {
        state.status.updateCall = "loading";
      })
      .addCase(updateCall.fulfilled, (state, action) => {
        state.status.updateCall = "succeeded";
        const index = state.calls.findIndex(
          (call) => call._id === action.payload._id
        );
        if (index !== -1) {
          state.calls[index] = action.payload;
        }
      })
      .addCase(updateCall.rejected, (state, action) => {
        state.status.updateCall = "failed";
        state.error.updateCall = action.payload;
      })

      // Delete call
      .addCase(deleteCall.pending, (state) => {
        state.status.deleteCall = "loading";
      })
      .addCase(deleteCall.fulfilled, (state, action) => {
        state.status.deleteCall = "succeeded";
        state.calls = state.calls.filter(
          (call) => call._id !== action.payload
        );
      })

      .addCase(deleteCall.rejected, (state, action) => {
        state.status.deleteCall = "failed";
        state.error.deleteCall = action.payload;
      })

      // Fetch calls daily report
      .addCase(fetchDailyReport.pending, (state) => {
        state.status.fetchDailyReport = "loading";
      })
      .addCase(fetchDailyReport.fulfilled, (state, action) => {
        state.status.fetchDailyReport = "succeeded";
        state.dailyReport = action.payload;
      })
      .addCase(fetchDailyReport.rejected, (state, action) => {
        state.status.fetchDailyReport = "failed";
        state.error.fetchDailyReport = action.payload;
      })

      // Fetch calls user report
      .addCase(fetchUserReport.pending, (state) => {
        state.status.fetchUserReport = "loading";
      })
      .addCase(fetchUserReport.fulfilled, (state, action) => {
        state.status.fetchUserReport = "succeeded";
        state.userReport = action.payload;
      })
      .addCase(fetchUserReport.rejected, (state, action) => {
        state.status.fetchUserReport = "failed";
        state.error.fetchUserReport = action.payload;
      })
  },
});

export default callSlice.reducer;

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import AddAssetModelPopup from "../../../../components/modelpopup/AddAssetModelPopup";
import AssetListFilter from "../../../../components/AssetListFilter";
import SearchBox from "../../../../components/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAllAssets,
  createAsset,
  updateAsset,
  deleteAsset,
} from "../../../../store/slices/AssetsSlice";
import { toast } from "react-toastify";
import Loading from "../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../hooks/utils/Error/Error";
import { formatAccessories, formatAssignedUser, handleError, filterAssets } from "../../../../hooks/utils/assetsHelper";
import ActionMenu from "./ActionsMenu";
 

const Assets = () => {
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [mode, setMode] = useState();
  const [filteredAssets, setFilteredAssets] = useState([]);
  const dispatch = useDispatch();
  const { assets, status, error } = useSelector((state) => state?.assets || {});

  useEffect(() => {
    dispatch(fetchAllAssets());
  }, [dispatch]);

  useEffect(() => {
    setFilteredAssets(assets);
  }, [assets]);
  // Handle filter changes
  const handleFilterChange = (filters) => {
    const filtered = assets.filter((asset) => filterAssets(asset, filters));
    setFilteredAssets(filtered);
  };

  const handleAddAsset = async (assetData) => {
    try {
      await dispatch(createAsset(assetData)).unwrap();
      toast.success("Asset added successfully");
    } catch (error) {
      handleError(error, "Failed to add asset");
    }
  };

  const handleUpdateAsset = async (assetData) => {
    try {
      await dispatch(updateAsset({ id: selectedAsset._id, assetData })).unwrap();
      toast.success("Asset updated successfully");
      resetSelection();
    } catch (error) {
      handleError(error, "Failed to update asset");
    }
  };

  const handleDelete = async () => {
    if (selectedAsset) {
      try {
        await dispatch(deleteAsset(selectedAsset._id)).unwrap();
        toast.success("Asset deleted successfully");
        resetSelection();
      } catch (error) {
        handleError(error, "Failed to delete asset");
      }
    }
  };

  // Function to handle editing an asset
  const handleEditAsset = (asset) => {
    setSelectedAsset(asset);  // Set the asset for editing
    setMode("edit");          // Set the mode to edit
  };

  // Function to handle deleting an asset
  const handleDeleteAsset = (asset) => {
    setSelectedAsset(asset);  // Set the asset for deletion
    document.getElementById("delete").click(); // Trigger delete modal if required
  };

  const resetSelection = () => {
    setSelectedAsset(null);
    setMode(null);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Assets"
            title="Dashboard"
            subtitle="Assets"
            modal="#edit_asset"
            name="Add Asset"
            modalClass="col-auto float-end ms-auto"
          />
          <AssetListFilter onFilterChange={handleFilterChange} />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns(handleEditAsset, handleDeleteAsset)} // Pass the functions to the columns
                  dataSource={prepareAssetsForDisplay(filteredAssets)}
                  className="table-striped"
                  rowKey={(record) => record._id}
                  locale={getTableLocale(status, error)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddAssetModelPopup
        onSubmit={handleAddAsset}
        onEdit={handleUpdateAsset}
        editAsset={selectedAsset}
        mode={mode}
      />
      <DeleteModal Name="Delete Asset" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default Assets;

// Helper to format assets for display
const prepareAssetsForDisplay = (assets) => {
  return assets.map((asset, index) => ({
    key: index,
    id: index + 1,
    ...asset,
    purchaseDate: new Date(asset.purchaseDate).toLocaleDateString(),
    assignedUserDisplay: formatAssignedUser(asset.assignedUser),
    accessoriesDisplay: formatAccessories(asset.accessories),
  }));
};

// Table columns
const columns = (handleEditAsset, handleDeleteAsset) => [
  { title: "#", dataIndex: "id", width: "5%" },
  { title: "Asset Name", dataIndex: "name", width: "10%" },
  { title: "Asset ID", dataIndex: "assetId", width: "10%" },
  { title: "Purchase Date", dataIndex: "purchaseDate", width: "10%" },
  { title: "Assigned User", dataIndex: "assignedUserDisplay", width: "10%" },
  { title: "Status", dataIndex: "status", width: "10%" },
  { title: "OS", dataIndex: ["specs", "os"], width: "10%" },
  { title: "RAM", dataIndex: ["specs", "ram"], width: "10%" },
  { title: "SSD", dataIndex: ["specs", "ssd"], width: "10%" },
  { title: "HDD", dataIndex: ["specs", "hdd"], width: "10%" },
  { title: "Processor", dataIndex: ["specs", "processor"], width: "10%" },
  { title: "Accessories", dataIndex: "accessoriesDisplay", width: "15%" },
  {
    title: "Action",
    className: "text-end",
    render: (_, record) => (
      <ActionMenu
        record={record}
        onEdit={handleEditAsset}
        onDelete={handleDeleteAsset} 
      />
    ),
    width: "10%",
  },
];

// Utility for table locale (loading, errors, empty)
const getTableLocale = (status, error) => {
  return {
    emptyText: status?.fetchAllAssets === "loading" ? (
      <Loading />
    ) : error?.fetchAllAssets ? (
      <div className="text-center text-danger">
        <Error404 />
      </div>
    ) : (
      "No Data"
    ),
  };
};

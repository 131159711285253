import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import Loading from "../../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../../hooks/utils/Error/Error";
import { deleteCall, fetchAllCalls } from "../../../../../store/slices/CallSlice";

const CallsTable = ({
  filter
}) => {
  const dispatch = useDispatch();
  const { calls: apiData } = useSelector((state) => state?.calls || {});
  const { total } = useSelector((state) => state?.calls || '0');
  const [selectedCall, setSelectedCall] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(fetchAllCalls({ page, limit, filter }));
  }, [dispatch, page, limit, filter])

  const filteredData = apiData || [];

  const salesElements = filteredData.map((call, index) => ({
    key: index,
    id: call._id,
    callId: call.callId || 'N/A',
    date: new Date(call.createdAt).toLocaleDateString() || "N/A",
    salesAssociate: call.user ? `${call.user?.firstName} ${call.user?.lastName}` : "N/A",
    primaryContact: call.primaryContact || '',
    dialer: call.dialer,
    type: call.type || "N/A",
    reason: call.reason || "N/A",
    comment: call.comment || '',
    sale: call.sale ? call.sale.saleId : "N/A"
  }));

  const columns = [
    {
      title: "Call ID",
      dataIndex: "callId",
      sorter: (a, b) => a.callId.localeCompare(b.callId),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Sales Associate",
      dataIndex: "salesAssociate",
    },
    {
      title: "Primary Contact",
      dataIndex: "primaryContact",
    },
    {
      title: "Dialer",
      dataIndex: "dialer",
    },
    {
      title: "Call Type",
      dataIndex: "type",
    },
    {
      title: "Reason (if Exempted)",
      dataIndex: "reason",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      onCell: () => ({
        style: {
          maxWidth: "350px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      }),
    },
    {
      title: "Sale ID",
      dataIndex: "sale",
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to={`/edit-call?id=${record?.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteCall(record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "10%",
    },
  ];

  const handleDeleteCall = (estimate) => {
    setSelectedCall(estimate);
  };

  const handleDelete = () => {
    if (selectedCall) {
      dispatch(deleteCall(selectedCall.id));
    }
  };

  const isLoading = false;
  const isError = false;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive mb-3">
          <Table
            columns={columns}
            dataSource={isLoading || isError ? [] : salesElements}
            rowKey={(record) => record.id}
            className="table table-striped custom-table mb-0"
            locale={{
              emptyText: isLoading ? (
                <Loading />
              ) : isError ? (
                <Error404 />
              ) : (
                "No Data"
              ),
            }}
            pagination={false}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            current={page}
            pageSize={limit}
            total={total} // Total Sales from Redux
            onChange={(page, limit) => {
              setPage(page);
              setLimit(limit);
            }}
            showSizeChanger
            pageSizeOptions={["10", "20", "50", "100"]}
            showQuickJumper
            showTotal={(total) => `Total ${total} calls`}
            itemRender={(page, type, originalElement) => {
              if (type === "prev") {
                return <p>Previous</p>;
              }
              if (type === "next") {
                return <p>Next</p>;
              }
              if (type === "jump-prev" || type === "jump-next") {
                return <p>...</p>;
              }
              return originalElement;
            }}
          />
        </div>
      </div>
      <DeleteModal Name="Delete Call" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default CallsTable;

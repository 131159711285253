import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./sidebardata";
import { useSelector } from "react-redux";

const Sidebar = ({isSidebarExpanded}) => {
  const location = useLocation();
  const pathname = location.pathname;

  const { role } = useSelector((state) => ({
    role: state?.auth?.userInfo?.role,
  }));

  const [sidebarData, setSidebarData] = useState(SidebarData);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [submenuDrop, setSubmenudrop] = useState(false);

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };
  const { t } = useTranslation();

  const expandSubMenus = (menu) => {
    sessionStorage.setItem("menuValue", menu.menuValue);
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      updatedSection.menu = section.menu.map((menuItem) =>
        menu.menuValue !== menuItem.menuValue
          ? {
            ...menuItem,
            showSubRoute: false,
          }
          : {
            ...menuItem,
            showSubRoute: !menu.showSubRoute,
          }
      );
      return updatedSection;
    });
    setSidebarData(updatedAdminSidebar);
  };

  const arrowDrop = () => {
    setSubmenudrop(!submenuDrop);
  };

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : ""}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: false }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide={false}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul className="sidebar-vertical" id="veritical-sidebar">
              {sidebarData.map((mainTittle, index) => {
                return (
                  <>
                    {(!mainTittle.role || mainTittle.role.includes(role)) && (
                      <>
                        <li className="menu-title" key={index + 1}>
                          <span>{t(mainTittle.tittle)}</span>
                          {mainTittle?.tittle === "CRM" ? (
                            <small class="newly-added-features">New</small>
                          ) : (
                            ""
                          )}
                        </li>
                        {mainTittle.menu.map((menu, menuIndex) => {
                          return (
                            <>
                              {
                                (menu?.role.includes(role)) && <>
                                  {menu.hasSubRoute === false ? (
                                    <li
                                      key={menuIndex + 1}
                                      className={
                                        pathname === menu.route ? "active" : ""
                                      }
                                    >
                                      <Link to={menu.route}>
                                        <i className={menu?.icon} />
                                        <span>{t(menu.menuValue)}</span>
                                      </Link>
                                    </li>
                                  ) : (
                                    <li key={menuIndex + 1} className="submenu">
                                      <Link
                                        to="#"
                                        onClick={() => expandSubMenus(menu)}
                                        className={
                                          menu.showSubRoute ? "subdrop" : ""
                                        }
                                      >
                                        <i className={menu?.icon} />
                                        <span
                                          className={
                                            menu?.menuValue === "Employees"
                                              ? "noti-dot"
                                              : ""
                                          }
                                        >
                                          {t(menu.menuValue)}
                                        </span>
                                        <span className="menu-arrow"></span>
                                      </Link>
                                      <ul
                                        style={{
                                          display: menu.showSubRoute
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        {menu.subMenus.map((subMenus, subMenu) => {
                                          return (
                                            <>
                                              {subMenus?.showMenuRoute === true ? (
                                                <li key={subMenu + 1}>
                                                  <Link
                                                    to={subMenus.route}
                                                    className={
                                                      submenuDrop ? "subdrop" : ""
                                                    }
                                                    onClick={arrowDrop}
                                                  >
                                                    {t(subMenus.menuValue)}
                                                    <span className="menu-arrow"></span>
                                                  </Link>

                                                  <ul
                                                    style={{
                                                      display: submenuDrop
                                                        ? "block"
                                                        : "none",
                                                    }}
                                                  >
                                                    {subMenus?.subMenusValues?.map(
                                                      (value, index) => {
                                                        return (
                                                          <li key={index}>
                                                            <span>
                                                              <Link
                                                                to={value.route}
                                                              >
                                                                <span>
                                                                  {t(
                                                                    value.menuValue
                                                                  )}{" "}
                                                                </span>
                                                              </Link>
                                                            </span>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </li>
                                              ) : (
                                                <li key={subMenu + 1}>
                                                  <Link
                                                    to={subMenus.route}
                                                    className={
                                                      pathname === subMenus?.route
                                                        ? "active"
                                                        : ""
                                                    }
                                                  >
                                                    {t(subMenus.menuValue)}
                                                  </Link>

                                                  <ul>
                                                    {subMenus?.subMenusValues?.map(
                                                      (value, index) => {
                                                        return (
                                                          <li key={index}>
                                                            <Link
                                                              to={value.route}
                                                              className={
                                                                pathname ===
                                                                  value?.route
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                            >
                                                              {t(value.menuValue)}
                                                            </Link>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </li>
                                              )}
                                            </>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  )}
                                </>
                              }

                            </>
                          );
                        })}
                      </>
                    )}
                  </>
                );
              })}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

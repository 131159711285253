import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector, useDispatch } from "react-redux";
import { fetchDailyReport } from "../../../../../store/slices/CallSlice";

const getMonthName = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("default", { month: "long" });
};

const Charts = () => {
  const dispatch = useDispatch();
  const { dailyReport } = useSelector((state) => state?.calls || {});

  const loggedInUserId = useSelector((state) => state?.auth?.userInfo?.id);

  const [chartData, setChartData] = useState([]);

  const getStartAndEndDate = () => {
    const today = new Date();

    const endDate = today.toISOString().split("T")[0];

    const startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1)
      .toISOString()
      .split("T")[0];

    return { startDate, endDate };
  };

  useEffect(() => {
    const { startDate, endDate } = getStartAndEndDate();
    dispatch(fetchDailyReport({ startDate, endDate }));
  }, [dispatch]);

  useEffect(() => {

    if (dailyReport) {
      const userReport = dailyReport.filter(
        (report) => report.userId === loggedInUserId
      );

      const aggregatedData = userReport.reduce((acc, report) => {
        const month = getMonthName(report.date);

        if (!acc[month]) {
          acc[month] = { month, "Total Sales": 0, "Total Revenue": 0 };
        }

        acc[month]["Total Sales"] += report.totalCalls;
        acc[month]["Total Revenue"] += report.acceptedTransactionTotal;

        return acc;
      }, {});

      const formattedData = Object.values(aggregatedData);
      setChartData(formattedData);
    }
  }, [dailyReport, loggedInUserId]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-12 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Your Sales Overview</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={chartData}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid />
                      <XAxis
                        dataKey="month"
                        padding={{ left: 30, right: 30 }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Total Sales"
                        stroke="#ff9b44"
                        fill="#00c5fb"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                      <Line
                        type="monotone"
                        dataKey="Total Revenue"
                        stroke="#fc6075"
                        fill="#0253cc"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useFetch from "../../../hooks/api/fetchHook";
import { Avatar_09 } from "../../../Routes/ImagePath";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import Loading from "../../../hooks/utils/Loading/Loading";
import Error404 from "../../../hooks/utils/Error/Error";
import { Table, Pagination } from "antd";
import Select from "react-select";

const TableAvatar = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const  = useSelector((state) => state?.employee?.total);
  const userToken = useSelector((state) => state?.auth?.userToken);
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData, isLoading, isError } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/users/?page=${currentPage}&limit=${pageSize}`,
    options
  );
  const total = useSelector((state) => state?.employee?.total);

  const [users, setUsers] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [searchName, setSearchName] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [focused, setFocused] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const monthOptions = [
    { value: null, label: "Select Month" },
    ...Array.from({ length: 12 }, (_, i) => ({
      value: i + 1,
      label: new Date(0, i).toLocaleString("default", { month: "long" }),
    })),
  ];

  const yearOptions = [
    { value: null, label: "Select Year" },
    ...Array.from({ length: 10 }, (_, i) => ({
      value: 2020 + i,
      label: (2020 + i).toString(),
    })),
  ];
  useEffect(() => {
    if (apiData && apiData.data.users && apiData.data.users.length > 0) {
      setUsers(apiData.data.users);

      const currentAttendance = {};
      apiData.data.users.forEach((user) => {
        const userId = user._id;
        currentAttendance[userId] = {};

        // Map user attendance by day, month, and year
        user.attendance.forEach((entry) => {
          const entryDate = new Date(entry.date);
          const day = entryDate.getDate();
          const month = entryDate.getMonth() + 1; // Months are 0-based
          const year = entryDate.getFullYear();
          const dateKey = `${day}-${month}-${year}`;
          currentAttendance[userId][dateKey] = entry;
        });
      });
  
      setAttendance(currentAttendance); // Store attendance per user by date
    }
  }, [apiData, pageSize]);

  const handleShowAttendance = (userId, dateKey) => {
    setSelectedAttendance(attendance[userId][dateKey]);
  };

  const handleSearch = () => {
    if (!searchMonth || !searchYear) {
      alert("Please select a valid month and year");
      return;
    }

    const filtered = users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`;
      const matchName = fullName
        .toLowerCase()
        .includes(searchName.toLowerCase());

      const hasAttendanceInMonthYear = user.attendance.some((entry) => {
        const entryDate = new Date(entry.date);
        const entryMonth = entryDate.getMonth() + 1;
        const entryYear = entryDate.getFullYear();
        return (
          entryMonth === parseInt(searchMonth) &&
          entryYear === parseInt(searchYear)
        );
      });

      return matchName && hasAttendanceInMonthYear;
    });

    setFilteredUsers(filtered);
  };

  const currentUsers = filteredUsers.length > 0 ? filteredUsers : users;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error404 />;
  }

  const getFormattedDate = (day, month, year) => {
    // Ensure that day, month, and year are valid numbers
    const formattedDay = String(day).padStart(2, '0');  // Ensure day is two digits
    const formattedMonth = String(month).padStart(2, '0');  // Ensure month is two digits
    return `${formattedDay}-${formattedMonth}-${year}`;
  };
  const columns = [
    {
      title: "Employee",
      dataIndex: "employee",
      key: "employee",
      render: (text, record) => (
        <h2 className="table-avatar">
          <Link className="avatar avatar-xs" to="/profile">
            <img src={record.image ? record.image : Avatar_09} alt="" />
          </Link>
          <Link to="/profile">
            {record.firstName} {record.lastName}
          </Link>
        </h2>
      ),
    },
    // Generate columns for days of the month
    ...Array.from({ length: 31 }, (_, i) => ({
      title: i + 1, // Day number as column title
      dataIndex: `day${i + 1}`,
      key: `day${i + 1}`,
      render: (text, record) => {
        const searchMonthValue = searchMonth || new Date().getMonth() + 1;
        const searchYearValue = searchYear || new Date().getFullYear();
        const dateKey = getFormattedDate(i + 1, searchMonthValue, searchYearValue);
      
        return attendance[record._id] && attendance[record._id][dateKey] ? (
          <Link
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#attendance_info"
            onClick={() => handleShowAttendance(record._id, dateKey)}
          >
            <i className="fa fa-check text-success" />
          </Link>
        ) : (
          <i className="fa fa-close text-danger" />
        );
      }
      
    })),
  ];

  const handlePageChange = (currentPage, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
  };

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focused || searchName !== ""
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={searchName}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(searchName !== "")}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <label className="focus-label" onClick={() => setFocused(true)}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={monthOptions}
              placeholder="Select Month"
              onChange={(selectedOption) =>
                setSearchMonth(selectedOption?.value || "")
              }
              value={monthOptions.find(
                (option) => option.value === searchMonth
              )}
            />
            <label className="focus-label">Select Month</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={yearOptions}
              placeholder="Select Year"
              onChange={(selectedOption) =>
                setSearchYear(selectedOption?.value || "")
              }
              value={yearOptions.find((option) => option.value === searchYear)}
            />
            <label className="focus-label">Select Year</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <Link
            className="btn btn-success btn-block w-100"
            onClick={handleSearch}
          >
            Search
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive mb-3">
            <Table
              columns={columns}
              dataSource={currentUsers}
              rowKey="id"
              className="table table-striped custom-table mb-0"
              pagination={false}
              locale={{
                emptyText: isLoading ? (
                  <Loading />
                ) : isError ? (
                  <Error404 />
                ) : (
                  "No Data"
                ),
              }}
            />
          </div>
        </div>
      </div>
      {/* Pagination Component */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={total}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={["10", "20", "50", "100"]}
          showQuickJumper
          showTotal={(total) => `Total ${total} employees`}
          itemRender={(page, type, originalElement) => {
            if (type === "prev") {
              return <a href="/****">Previous</a>;
            }
            if (type === "next") {
              return <a href="/****">Next</a>;
            }
            if (type === "jump-prev" || type === "jump-next") {
              return <a href="/****">...</a>;
            }
            return originalElement;
          }}
        />
      </div>

      <AttendenceModelPopup attendanceData={selectedAttendance} />
    </>
  );
};

export default TableAvatar;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  addEmployee,
  fetchDepartments,
  fetchDesignations, // Use fetchDesignations directly
} from "../../store/slices/EmployeeSlice";
import { toast } from "react-toastify";

const AllEmployeeAddPopup = () => {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state?.employee?.departments);
  const designations = useSelector((state) => state?.employee?.designations);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    department: "",
    designation: "",
    dateOfJoining: null,
    phone: "",
    weeklyOffDays: 1,
    startTime: new Date(new Date().setHours(18, 0, 0, 0)),
    endTime: new Date(new Date().setHours(4, 0, 0, 0)),
  });

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  const weeklyOffOptions = [{ value: 1, label: 1 }, { value: 2, label: 2 }];

  const startTimeOptions = [
    { value: 16, label: '04:00 PM' },
    { value: 17, label: '05:00 PM' },
    { value: 18, label: '06:00 PM' },
    { value: 19, label: '07:00 PM' },
    { value: 20, label: '08:00 PM' },
    { value: 21, label: '09:00 PM' }
  ];

  const endTimeOptions = [
    { value: 3, label: '03:00 AM' },
    { value: 4, label: '04:00 AM' },
    { value: 5, label: '05:00 AM' },
    { value: 6, label: '06:00 AM' },
    { value: 7, label: '07:00 AM' },
    { value: 8, label: '08:00 AM' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dateOfJoining: date });
  };

  const handleDepartmentChange = (selectedOption) => {
    setFormData({ ...formData, department: selectedOption.value, designation: "" });
    dispatch(fetchDesignations(selectedOption.value)); // Fetch designations based on selected department
  };

  const handleDesignationChange = (selectedOption) => {
    setFormData({ ...formData, designation: selectedOption.value });
  };

  const handleWeekOffDaysChange = (selectedOption) => {
    setFormData({ ...formData, weeklyOffDays: selectedOption.value });
  };

  const handleStartTimeChange = (selectedOption) => {
    const time = new Date(new Date().setHours(selectedOption.value, 0, 0, 0));
    console.log('time', time)
    setFormData({ ...formData, startTime: time });
  };

  const handleEndTimeChange = (selectedOption) => {
    const time = new Date(new Date().setHours(selectedOption, 0, 0, 0));
    setFormData({ ...formData, endTime: time });
  };

  const dropdownStyle = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#cb6ce6' : state.isFocused ? '#f8defff5' : undefined,
      color: state.isSelected ? '#fff' : undefined,
      '&:active': {
        backgroundColor: '#f8defff5',
      },
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addEmployee(formData))
      .unwrap()  // unwrap to handle fulfilled/rejected action outside of .then/.catch
      .then((result) => {
        toast.success("Employee added successfully!");
      })
      .catch((error) => {
        toast.error("Failed to add employee. Please try again.");
      });
  };
  return (
    <>
      <div id="add_employee" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Employee</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Phone</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Department
                      </label>
                      <Select
                        options={departments?.map((dept) => ({
                          value: dept._id,
                          label: dept.name,
                        }))}
                        placeholder="Select"
                        name="department"
                        onChange={handleDepartmentChange}
                        styles={dropdownStyle}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Designation
                      </label>
                      <Select
                        options={designations?.map((des) => ({
                          value: des._id,
                          label: des.title,
                        }))}
                        placeholder="Select"
                        name="designation"
                        onChange={handleDesignationChange}
                        isDisabled={!formData.department}
                        styles={dropdownStyle}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Joining Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <DatePicker
                          selected={formData.dateOfJoining}
                          required
                          onChange={handleDateChange}
                          className="form-control floating datetimepicker"
                          type="date"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Weekly Off Days <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="weeklyOffDays"
                        options={weeklyOffOptions}
                        placeholder="Select Weekly Off Days"
                        styles={dropdownStyle}
                        defaultValue={weeklyOffOptions.find(option => option.value === formData.weeklyOffDays)}
                        onChange={handleWeekOffDaysChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Shift Start Time <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="startTime"
                        options={startTimeOptions}
                        placeholder="Select Shift Start Time"
                        styles={dropdownStyle}
                        defaultValue={startTimeOptions.find(option => option.value === formData.startTime.getHours())}
                        onChange={handleStartTimeChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Shift End Time <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="endTime"
                        options={endTimeOptions}
                        placeholder="Select Shift End Time"
                        styles={dropdownStyle}
                        defaultValue={endTimeOptions.find(option => option.value === formData.endTime.getHours())}
                        onChange={handleEndTimeChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEmployeeAddPopup;

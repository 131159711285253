export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    role: ["admin", "hr", "manager", "supervisor"], // Title on role based 
    menu: [
      {
        menuValue: "All Employees",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employees",
        icon: "la la-user",
        role: ["admin", "hr", "manager"], // individual menu hide on role based 
      },
      {
        menuValue: "Leaves",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/admin-leaves",
        icon: "la la-calendar-check-o",
        role: ["admin", "hr"], // individual menu hide on role based 
      },
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/admin-attendance",
        icon: "la la-clock-o",
        role: ["admin", "hr", "manager", "supervisor"], // individual menu hide on role based 
      },
      {
        menuValue: "Departments",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/departments",
        icon: "la la-building-o",
        role: ["admin", "hr"], // individual menu hide on role based 
      },
      {
        menuValue: "Designations",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/designations",
        icon: "la la-briefcase",
        role: ["admin", "hr"], // individual menu hide on role based 
      },
      {
        menuValue: "Assets",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/assets",
        icon: "la la-laptop",
        role: ["admin", "hr", "manager", "supervisor"], // individual menu hide on role based 
      },
      {
        menuValue: "Holidays",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/holidays",
        icon: "la la-umbrella-beach",
        role: ["admin", "hr"], // individual menu hide on role based 
      },
      {
        menuValue: "Sales",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["admin", "manager"], // individual menu hide on role based 

        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Sales",
            route: "/sales",
          },
          {
            menuValue: "Calls",
            route: "/calls",
          },
          {
            menuValue: "Fields",
            route: "/fields",
          },
        ],
      },
      {
        menuValue: "Sales Report",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["admin", "manager"],
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Daily Report",
            route: "/daily-report",
          },
          {
            menuValue: "User Report",
            route: "/user-report",
          },
        ],
      },
      // {
      //   menuValue: "Payroll",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   role: ["admin"], // individual menu hide on role based 

      //   icon: "la la-money",
      //   subMenus: [
      //     {
      //       menuValue: "Employee Salary",
      //       route: "/employee-salary",
      //     },
      //     {
      //       menuValue: "Payslip",
      //       route: "/salary-view",
      //     },
      //     {
      //       menuValue: "Payroll Items",
      //       route: "/payroll-items",
      //     },
      //   ],
      // },
    ],
  },
  {
    tittle: "Employee",
    showAsTab: false,
    separateRoute: false,
    role: ["employee", "hr", "manager", "supervisor"], // Title hide on role based 
    menu: [
      {
        menuValue: "Attendance",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/attendance-employee",
        icon: "la la-clock-o",
        role: ["employee", "hr", "manager", "supervisor"], // individual menu hide on role based 
      },
      {
        menuValue: "Leaves",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/leaves-employee",
        icon: "la la-calendar-check-o",
        role: ["employee", "hr", "manager", "supervisor"], // individual menu hide on role based 
      },
      {
        menuValue: "Sales",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee", "supervisor"], // individual menu hide on role based 
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Sales",
            route: "/sales",
          },
          {
            menuValue: "Calls",
            route: "/calls",
          },
          {
            menuValue: "Fields",
            route: "/fields",
          },
        ],
      },
      {
        menuValue: "Sales Report",
        hasSubRoute: true,
        showSubRoute: false,
        role: ["employee", "supervisor"], // individual menu hide on role based 
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Daily Report",
            route: "/daily-report",
          },
          {
            menuValue: "User Report",
            route: "/user-report",
          },
        ],
      },
    ],
  },
];

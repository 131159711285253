import React, { useEffect, useState } from "react";
import axios from "axios";
import { Avatar_02 } from "../../Routes/ImagePath";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useSelector } from "react-redux";
import { fetchDesignations } from "../../store/slices/EmployeeSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { fetchUserDetails } from "../../store/slices/UserSlice";
import Loading from "../../hooks/utils/Loading/Loading";

const PersonalInformationModelPopup = ({
  userId,
  editModalName,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [preview, setPreview] = useState(null);
  const [documents, setDocuments] = useState([
    { id: "resume", preview: null, isPdf: false },
    { id: "cnic", preview: null, isPdf: false },
    { id: "expLetter", preview: null, isPdf: false },
    { id: "degree", preview: null, isPdf: false },
  ]);
  // const designations = useSelector((state) => state?.employee?.designations);

  const token = useSelector((state) => state?.auth?.userInfo?.userToken);
  const profileInfo = useSelector(
    (state) => state?.user?.userDetails?.data?.user
  );
  const [formData, setFormData] = useState({});
  const [updatedData, setUpdatedData] = useState({});

  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  // const transformedData = departmentList.map((item) => ({
  //   value: item._id,
  //   label: item.name,
  // }));

  useEffect(() => {
    setFormData(profileInfo);

    if (editModalName === "education") {
      setUpdatedData((prevData) => ({
        ...prevData,
        education: profileInfo?.education || [],
      }));
    } else if (editModalName === "experience") {
      setUpdatedData((prevData) => ({
        ...prevData,
        experience: profileInfo?.experience || [],
      }));
    }
  }, [editModalName, profileInfo]);

  useEffect(() => {
    if (profileInfo?.department) {
      dispatch(fetchDesignations(profileInfo?.department?._id));
    }
  }, [dispatch, profileInfo?.department]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setUpdatedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleChange("image", selectedFile);
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }
  };
  const handleFileChangeanother = (index, event) => {
    const file = event.target.files[0];
    const isPdf = file.type === "application/pdf";
    const preview = URL.createObjectURL(file);

    setDocuments((prevDocs) => {
      const updatedDocs = [...prevDocs];
      updatedDocs[index] = { ...updatedDocs[index], preview, isPdf };
      return updatedDocs;
    });

    const field = documents[index].id;
    handleChange(field, file);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/users`,
      formData,
      config
    );

    return response.data.fileUrl;
  };

  const handleAddForm = (form) => {
    setFormData((prevData) => {
      const updatedData = [...prevData[form]];
      updatedData.push({});

      return {
        ...prevData,
        [form]: updatedData,
      };
    });
    setUpdatedData((prevData) => {
      const updatedData = [...prevData[form]];
      updatedData.push({});

      return {
        ...prevData,
        [form]: updatedData,
      };
    });
  };

  const handleFieldChange = (index, field, value, parentField) => {
    setFormData((prevData) => {
      const updatedDate = [...prevData[parentField]];
      updatedDate[index] = {
        ...updatedDate[index],
        [field]: value,
      };

      return {
        ...prevData,
        [parentField]: updatedDate,
      };
    });
    setUpdatedData((prevData) => {
      const updatedDate = [...prevData[parentField]];

      updatedDate[index] = {
        ...updatedDate[index],
        [field]: value,
      };

      return {
        ...prevData,
        [parentField]: updatedDate,
      };
    });
  };

  const handleDeleteForm = async (index, form) => {
    setFormData((prevData) => {
      const updatedData = [...prevData[form]];
      const filteredData = updatedData.filter((_, i) => i !== index);

      return {
        ...prevData,
        [form]: filteredData,
      };
    });
    setUpdatedData((prevData) => {
      const updatedData = [...prevData[form]];
      const filteredData = updatedData.filter((_, i) => i !== index);

      return {
        ...prevData,
        [form]: filteredData,
      };
    });
  };

  const handleSubmit = async (e, section) => {
    e.preventDefault();

    setLoading(true);

    const data = new FormData();

    if (formData.documents) {
      try {
        if (formData.documents.resume) {
          const resumeUrl = await uploadFile(formData.documents.resume);
          data.append("resume", resumeUrl);
        }
        if (formData.documents.cnic) {
          const cnicUrl = await uploadFile(formData.documents.cnic);
          data.append("cnic", cnicUrl);
        }
        if (formData.documents.expLetter) {
          const expLetterUrl = await uploadFile(formData.documents.expLetter);
          data.append("expLetter", expLetterUrl);
        }
        if (formData.documents.degree) {
          const degreeUrl = await uploadFile(formData.documents.degree);
          data.append("degree", degreeUrl);
        }
      } catch (error) {
        toast.error("Failed to upload documents.");
        setLoading(false);
        return;
      }
    } else {
      toast.error("No documents to upload.");
      setLoading(false);
      return;
    }

    Object.keys(updatedData).forEach((key) => {
      if (key === "education" || key === "experience") {
        data.append(key, JSON.stringify(updatedData[key]));
      } else {
        data.append(key, updatedData[key]);
      }
    });

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${userId}`,
        data,
        config
      );

      if (response.status === 200) {
        toast.success(`${section.replace("-", " ")} updated successfully!`);
        setShowModal(false);
        setTimeout(() => {
          document.body.classList.remove("modal-popup");
          document.body.style.overflow = "auto";
          const modalBackdrop = document.querySelector(".modal-backdrop");
          if (modalBackdrop) {
            modalBackdrop.remove();
          }
        }, 300);
      }
    } catch (error) {
      console.error(
        `Error updating ${section}:`,
        error.response ? error.response.data : error.message
      );
      toast.error(`Failed to update ${section}. Please try again.`);
    }

    setLoading(false);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      {/* Profile Information Modal */}
      <div id="profile_info" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Profile Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleSubmit(e, "personal-info")}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={preview || (formData?.image ?? Avatar_02)}
                        alt="user"
                      />
                      <div className="fileupload btn">
                        <span className="btn-text">edit</span>
                        <input
                          className="upload"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your First Name"
                            value={formData?.firstName ?? ""}
                            onChange={(e) =>
                              handleChange("firstName", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Last Name"
                            defaultValue={formData?.lastName ?? ""}
                            onChange={(e) =>
                              handleChange("lastName", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Your Phone Number"
                            defaultValue={formData?.phone ?? ""}
                            onChange={(e) =>
                              handleChange("phone", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Your Email Address"
                            defaultValue={formData?.email ?? ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Birth Date</label>
                          <DatePicker
                            placeholder="DD-MM-YYYY"
                            maxDate={new Date()}
                            required
                            selected={formData?.dateOfBirth ?? ""}
                            onChange={(value) => {
                              const date = value ?? "";
                              handleChange("dateOfBirth", date);
                            }}
                            className="form-control floating datetimepicker"
                            dateFormat="dd-MM-yyyy"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Gender</label>
                          <Select
                            options={gender}
                            placeholder="Gender"
                            styles={customStyles}
                            defaultInputValue={formData?.gender ?? ""}
                            value={gender?.find(
                              (g) => g.value === formData?.gender
                            )}
                            onChange={(option) =>
                              handleChange("gender", option.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Department <span className="text-danger">*</span>
                          </label>
                          <option
                            value=""
                            className="border rounded p-2 text-secondary"
                          >
                            {formData?.department?.name || "N/A"}
                          </option>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Designation <span className="text-danger">*</span>
                          </label>
                          <option
                            value=""
                            className="border rounded p-2 text-secondary"
                          >
                            {formData?.designation?.title}
                          </option>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Address</label>
                          <textarea
                            className="form-control"
                            placeholder="Enter Your Address"
                            defaultValue={formData?.address ?? ""}
                            onChange={(e) =>
                              handleChange("address", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Zip Code</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Zip Code"
                            defaultValue={formData?.zipCode ?? ""}
                            onChange={(e) =>
                              handleChange("zipCode", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Education Modal */}
      <div
        id="education_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Education Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleSubmit(e, "education-info")}>
                {formData?.education &&
                  formData?.education.map((education, index) => {
                    return (
                      <>
                        <div className="form-scroll">
                          <div className="card">
                            <div className="card-body">
                              <h3 className="card-title">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDeleteForm(index, "education")
                                  }
                                  className="delete-icon bg-white border border-0"
                                >
                                  <i className="fa-regular fa-trash-can" />
                                </button>
                              </h3>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Institution
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control floating"
                                      value={education?.institution || " "}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "institution",
                                          e.target.value,
                                          "education"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Subject
                                    </label>
                                    <input
                                      required
                                      type="text"
                                      className="form-control floating"
                                      value={education?.subject || " "}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "subject",
                                          e.target.value,
                                          "education"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Starting Date
                                    </label>
                                    <DatePicker
                                      placeholderText="DD-MM-YYYY"
                                      maxDate={new Date()}
                                      required
                                      selected={education?.startingDate ?? ""}
                                      onChange={(value) => {
                                        const date = value ?? "";
                                        handleFieldChange(
                                          index,
                                          "startingDate",
                                          date,
                                          "education"
                                        );
                                      }}
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Complete Date
                                    </label>
                                    <DatePicker
                                      placeholderText="DD-MM-YYYY"
                                      maxDate={new Date()}
                                      required
                                      selected={education?.completeDate ?? ""}
                                      onChange={(value) => {
                                        const date = value ?? "";
                                        handleFieldChange(
                                          index,
                                          "completeDate",
                                          date,
                                          "education"
                                        );
                                      }}
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Degree
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control floating"
                                      value={education?.degree || " "}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "degree",
                                          e.target.value,
                                          "education"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Grade
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control floating"
                                      defaultValue={education?.grade || " "}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "grade",
                                          e.target.value,
                                          "education"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                <div className="add-more">
                  <button
                    className="border border-0 bg-white"
                    onClick={() => handleAddForm("education")}
                    type="button"
                  >
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </button>
                </div>

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Experience Modal */}

      <div
        id="experience_info"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Experience Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => handleSubmit(e, "experience-info")}>
                {formData?.experience &&
                  formData?.experience.map((experience, index) => {
                    return (
                      <>
                        <div className="form-scroll">
                          <div className="card">
                            <div className="card-body">
                              <h3 className="card-title">
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDeleteForm(index, "experience");
                                  }}
                                  className="delete-icon bg-white border border-0"
                                >
                                  <i className="fa-regular fa-trash-can" />
                                </button>
                              </h3>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control floating"
                                      value={experience?.companyName}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "companyName",
                                          e.target.value,
                                          "experience"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Location
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control floating"
                                      value={experience?.location}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "location",
                                          e.target.value,
                                          "experience"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Job Position
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="form-control floating"
                                      value={experience?.jobPosition}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "jobPosition",
                                          e.target.value,
                                          "experience"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Period From
                                    </label>
                                    <DatePicker
                                      placeholderText="DD-MM-YYYY"
                                      maxDate={new Date()}
                                      required
                                      selected={experience?.periodFrom ?? ""}
                                      onChange={(value) => {
                                        const date = value ?? "";
                                        handleFieldChange(
                                          index,
                                          "periodFrom",
                                          date,
                                          "experience"
                                        );
                                      }}
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-block mb-3">
                                    <label className="col-form-label">
                                      Period To
                                    </label>
                                    <DatePicker
                                      placeholderText="DD-MM-YYYY"
                                      maxDate={new Date()}
                                      selected={experience?.periodTo ?? ""}
                                      onChange={(value) => {
                                        const date = value ?? "";
                                        handleFieldChange(
                                          index,
                                          "periodTo",
                                          date,
                                          "experience"
                                        );
                                      }}
                                      className="form-control floating datetimepicker"
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                <div className="add-more">
                  <button
                    className="border border-0 bg-white"
                    onClick={() => handleAddForm("experience")}
                    type="button"
                  >
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </button>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal-popup">
          {documents.map((doc, index) => (
            <div
              key={doc.id}
              id={doc.id}
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Upload{" "}
                      {doc.id === "resume"
                        ? "Resume"
                        : doc.id === "cnic"
                        ? "CNIC"
                        : doc.id === "expLetter"
                        ? "Experience Letter"
                        : "Degree"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="position-relative">
                    <div
                      className="position-absolute d-flex justify-content-center w-100 h-50 align-items-center"
                      style={{ zIndex: loading ? "2" : "1" }}
                    >
                      {loading && <Loading />}
                    </div>
                    <div
                      className="modal-body"
                      style={{
                        maxHeight: "80vh",
                        overflowY: "auto",
                        zIndex: loading ? "1" : "2",
                      }}
                    >
                      {doc.preview && !doc.isPdf ? (
                        <div className="profile-img-wrap edit-img">
                          <img
                            className="inline-block"
                            src={doc.preview}
                            alt="Document Preview"
                            style={{
                              objectFit: "cover",
                              maxWidth: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                      ) : doc.preview && doc.isPdf ? (
                        <div className="pdf-preview">
                          <iframe
                            src={doc.preview}
                            title="PDF Preview"
                            style={{
                              width: "100%",
                              height: "600px",
                              border: "none",
                            }}
                          />
                        </div>
                      ) : (
                        <div className="profile-img-wrap edit-img">
                          <img
                            className="inline-block"
                            src={Avatar_02}
                            alt="Default Preview"
                          />
                        </div>
                      )}
                      <div className="add-more ms-3">
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          className="form-control"
                          onChange={(e) => handleFileChangeanother(index, e)}
                        />
                      </div>
                      <form onSubmit={(e) => handleSubmit(e, doc.id)}>
                        <div className="submit-section">
                          <button
                            className="btn btn-primary submit-btn"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PersonalInformationModelPopup;

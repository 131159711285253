import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import Sidebar from "../../views/layout/Sidebar";
import Estimates from "../../views/pages/HR/Sales/Estimates";
import CreateEstimate from "../../views/pages/HR/Sales/Estimates/createEstimate.jsx";
import EditEstimate from "../../views/pages/HR/Sales/Estimates/EditEstimate.jsx";
import AllEmpoyee from "../../views/pages/Employees/AllEmpoyee";
import AdminLeave from "../../views/pages/Employees/AdminLeave";
import EmployeeLeave from "../../views/pages/Employees/EmployeeLeave";
import AttendenceAdmin from "../../views/pages/Employees/Attendenceadmin";
import AttendanceEmployee from "../../views/pages/Employees/AttendenceEmployee";
import Department from "../../views/pages/Employees/Department";
import Designation from "../../views/pages/Employees/Designation";
import Profile from "../../views/pages/Profile/Profile";
import EmployeeList from "../../views/pages/Employees/EmployeeList";
import EmployeeSalary from "../../views/pages/HR/Payroll/EmployeeSalary.jsx";
import PaySlip from "../../../src/views/pages/HR/Payroll/Payslip"
import PayrollItems from "../../../src/views/pages/HR/Payroll/PayrollItems.jsx"
import Fields from "../../views/pages/HR/Sales/Feilds/Fields.jsx";
import EmployeeProfile from "../../views/pages/Profile/EmployeeProfile.jsx";
import AdminDashboard from "../../views/pages/MainPages/Dashboard/AdminDashboard/adminDashboard.jsx"
import EmployeeDashboard from "../../views/pages/MainPages/Dashboard/EmployeeDashboard/index.jsx"
import Assets from "../../views/pages/HR/Assets/Assets.jsx";
import HolidayManagement from "../../views/pages/HR/Holiday/HolidayManagement.jsx";
import Calls from "../../views/pages/HR/Sales/Calls/index.jsx";
import CreateCall from "../../views/pages/HR/Sales/Calls/createCall.jsx";
import EditCall from "../../views/pages/HR/Sales/Calls/EditCall.jsx";
import DailyReport from "../../views/pages/HR/SalesReport/Daily.jsx";
import UserReport from "../../views/pages/HR/SalesReport/User.jsx";
import ProtectedRoute from "../../hooks/utils/ProtectedRoute.jsx";
import Error404 from "../../hooks/utils/Error/Error.jsx";

const AppContainer = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isSidebarExpanded, setSidebarExpanded] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const role = useSelector((state) => state?.auth?.userRole);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  const routingObjects = [
    {
      id: 1,
      path: "admin-dashboard",
      element: <AdminDashboard />,
      role: ['admin', 'manager']
    },
    {
      id: 2,
      path: "admin-leaves",
      element: <AdminLeave />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 3,
      path: "employees",
      element: <AllEmpoyee />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 4,
      path: "admin-attendance",
      element: <AttendenceAdmin />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 6,
      path: "departments",
      element: <Department />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 7,
      path: "designations",
      element: <Designation />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 8,
      path: "employeeprofile/:id",
      element: <EmployeeProfile />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 9,
      path: "employees-list",
      element: <EmployeeList />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 10,
      path: "assets",
      element: <Assets />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 11,
      path: "holidays",
      element: <HolidayManagement />,
      role: ['admin', 'manager', 'hr']
    },
    {
      id: 12,
      path: "employee-dashboard",
      element: <EmployeeDashboard />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 5,
      path: "attendance-employee",
      element: <AttendanceEmployee />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 13,
      path: "leaves-employee",
      element: <EmployeeLeave />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 14,
      path: "profile",
      element: <Profile />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 15,
      path: "salary-view",
      element: <PaySlip />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 16,
      path: "payroll-items",
      element: <PayrollItems />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 17,
      path: "employee-salary",
      element: <EmployeeSalary />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 18,
      path: "fields",
      element: <Fields />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 19,
      path: "sales",
      element: <Estimates />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 20,
      path: "create-sale",
      element: <CreateEstimate />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 21,
      path: "edit-sale",
      element: <EditEstimate />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 22,
      path: "calls",
      element: <Calls />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 23,
      path: "create-call",
      element: <CreateCall />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 24,
      path: "edit-call",
      element: <EditCall />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 25,
      path: "daily-report",
      element: <DailyReport />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 26,
      path: "user-report",
      element: <UserReport />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
    {
      id: 26,
      path: "error",
      element: <Error404 />,
      role: ['admin', 'manager', 'hr', 'supervisor', 'employee']
    },
  ];


  const SidebarLayout = () => (
    <>
      <Header setSidebarExpanded={setSidebarExpanded} isSidebarExpanded={isSidebarExpanded}/>
      <Sidebar isSidebarExpanded={isSidebarExpanded}/>
      <Outlet />
    </>
  );

  const mobileResponsive = (event) => {
    const excludedHeader = document.querySelector(".header");
    const excludedSidebar = document.querySelector(".sidebar");

    if (
      !excludedHeader.contains(event.target) &&
      !excludedSidebar.contains(event.target)
    ) {
      document.body.classList.remove("slide-nav");
    }
  };

  if (isLoggedIn && role !== 'admin' && screenWidth < 640) {
    return <h1>Access Denied: You are not authorized to use it on mobile.</h1>;
  }

  return (
    <>
      <div className="main-wrapper" onClick={mobileResponsive}>
        <Routes>
          <Route path={"/*"} element={<SidebarLayout />}>
            {routingObjects.map((item) => (
              <Route key={item.id} path={item.path}
                element=
                {
                  <ProtectedRoute children={item.element} requiredRole={item.role} />
                }
              />
            ))}
          </Route>

          <Route path={"/*"} element={<SidebarLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default AppContainer;

import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import useFetch from "../../../src/hooks/api/fetchHook";
import { toast } from "react-toastify";

const DepartmentModal = ({ onSubmit, onEdit, editDepartment, mode }) => {
  const { userToken } = useSelector((state) => state?.auth);
  const [departmentName, setDepartmentName] = useState("");
  const [description, setDescription] = useState("");
  const [manager, setManager] = useState(null);
  const [managers, setManagers] = useState([]);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const departmentTypeOptions = [
    { value: "hr", label: "HR" },
    { value: "sales", label: "Sales" },
    { value: "followup", label: "Follow-up" },
    { value: "quality", label: "Quality" },
    { value: "upwork", label: "Upwork" },
    { value: "marketing", label: "Marketing" },
    { value: "development", label: "Development" },
    { value: "surveillance", label: "Surveillance" },
  ];

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const {
    apiData,
    isLoading: fetchLoading,
    isError: fetchError,
  } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/users/?role=manager`,
    options
  );

  useEffect(() => {
    if (fetchLoading) {
      setIsLoading(true);
    } else if (fetchError) {
      setIsError(true);
      setIsLoading(false);
    } else if (apiData) {
      setManagers(apiData.data.users || []);
      setIsLoading(false);
    }
  }, [fetchLoading, fetchError, apiData]);

  useEffect(() => {
    if (mode === "edit" && editDepartment) {
      setDepartmentName(editDepartment.departmentName);
      setDescription(editDepartment.description);
      setManager({
        label: editDepartment.managerName,
        value: editDepartment.manager,
      });
      setType({
        value: editDepartment.type,
        label:
          editDepartment?.type?.charAt(0)?.toUpperCase() +
          editDepartment?.type?.slice(1),
      });
    } else {
      setDepartmentName("");
      setDescription("");
      setManager(null);
      setType(null);
    }
  }, [mode, editDepartment]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const departmentData = {
      name: departmentName,
      description: description,
      type: type?.value,
      manager: manager?.value,
    };

    if (mode === "edit") {
      onEdit(departmentData);
      toast.success("Department Updated Successfully!");
    } else {
      onSubmit(departmentData);
      toast.success("Department Added Successfully!");
    }

    const modalId = "edit_department";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement.querySelector(".btn-close");
    if (closeButton) closeButton.click();

    setDepartmentName("");
    setDescription("");
    setManager(null);
    setType(null);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#000" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 140,
      overflowY: "auto",
    }),
  };

  return (
    <>
      <div
        id="edit_department"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {mode === "edit" ? "Edit Department" : "Add Department"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Description <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Type
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Department Type"
                    value={type}
                    onChange={(option) => setType(option)}
                    options={departmentTypeOptions}
                    styles={customStyles}
                    required
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Manager</label>
                  <Select
                    placeholder="Select Manager"
                    value={manager}
                    onChange={(option) => setManager(option)}
                    options={managers.map((mgr) => ({
                      label: mgr.firstName + " " + mgr.lastName,
                      value: mgr._id,
                    }))}
                    styles={customStyles}
                    isLoading={isLoading}
                    noOptionsMessage={() =>
                      isError ? "Error loading managers" : "No managers found"
                    }
                  />
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentModal;

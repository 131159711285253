/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { avatar1, avatar2, employeeimg } from "../../../../../Routes/ImagePath";
import Chart from "react-apexcharts";
import Slider from "react-slick";
import { ArrowRightCircle } from "react-feather";
import { Tooltip } from "react-bootstrap";
import { fetchUserDetails } from "../../../../../store/slices/UserSlice";
import { useSelector, useDispatch } from "react-redux";
import { fetchLeavesByUserId } from "../../../../../store/slices/LeaveSlice";
import { fetchAllAssets } from "../../../../../store/slices/AssetsSlice";
import { fetchHolidays } from "../../../../../store/slices/HolidaySlice";
import useFetch from "../../../../../hooks/api/fetchHook";
// import TotalAbsences from "./LeaveAbsent";
import Holiday from "../AdminDashboard/holiday";
import Charts from "./charts";
import Loading from "../../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../../hooks/utils/Error/Error";

const EmployeeDashboard = () => {
  const [leaveNotifications, setLeaveNotifications] = useState([]);
  const [dismissedNotifications, setDismissedNotifications] = useState(
    JSON.parse(localStorage.getItem("dismissedNotifications")) || []
  );
  const dispatch = useDispatch();
  const holidays = useSelector((state) => state.holidays.holidays);
  const leaves = useSelector((state) => state?.leave?.leaves);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  // const userInfo = useSelector((state) => state?.auth?.userInfo);
  const { userDetails } = useSelector((state) => state?.user);
  const assets = useSelector((state) => state?.assets?.assets);

  const { userToken, AttendanceId } = useSelector((state) => ({
    userToken: state?.auth?.userToken,
    AttendanceId: state?.auth?.userInfo?.id,
  }));

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  function minutesToHoursFormatted(minutes) {
    const seconds = minutes * 60;
    const hours = Math.floor(minutes / 60);
    const min = Math.floor((seconds % 3600) / 60);
    return `${hours} Hrs : ${min} Min`;
  }

  function calculateRemainingTime(totalWorkedMinutes, shiftHours = 9) {
    const shiftMinutes = shiftHours * 60;
    const remainingMinutes = shiftMinutes - totalWorkedMinutes;

    if (remainingMinutes < 0) {
      return "0 Hrs : 0 Min";
    }

    return minutesToHoursFormatted(remainingMinutes);
  }

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn && userDetails?.data?.user?._id) {
        try {
          await dispatch(fetchUserDetails(userDetails.data.user._id));
          await dispatch(fetchLeavesByUserId(userDetails.data.user._id));
          await dispatch(fetchAllAssets());
          await dispatch(fetchHolidays());
        } catch (err) {
          console.error("Error fetching data:", err);
          setIsError(err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [dispatch, isLoggedIn, userDetails?.data?.user?._id]);

  useEffect(() => {
    if (assets?.length > 0 && userDetails?.data?.user?._id) {
      assets.filter(
        (asset) => asset.assignedUser?._id === userDetails?.data?.user?._id
      );
    }
  }, [assets, userDetails?.data?.user?._id]);

  useEffect(() => {
    if (leaves?.length > 0) {
      const notifications = leaves
        .filter((leave) => !dismissedNotifications.includes(leave._id))
        .map((leave) => {
          let message = "";
          let type = "";

          if (leave.status === "Approved") {
            message = `Your Leave Request on ${formatDate(
              leave.startDate
            )} has been Approved!`;
            type = "success";
          } else if (leave.status === "Rejected") {
            message = `Your Leave Request on ${formatDate(
              leave.startDate
            )} has been Rejected.`;
            type = "danger";
          } else if (leave.status === "Pending") {
            message = `Your Leave Request on ${formatDate(
              leave.startDate
            )} is Pending Approval.`;
            type = "warning";
          }

          return { message, type, id: leave._id };
        });

      setLeaveNotifications(notifications);
    }
  }, [leaves]);

  const handleDismiss = (id) => {
    setDismissedNotifications((prev) => {
      const updatedDismissed = [...prev, id];
      localStorage.setItem(
        "dismissedNotifications",
        JSON.stringify(updatedDismissed)
      );
      return updatedDismissed;
    });

    setLeaveNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  };

  const { apiData } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/attendance/${AttendanceId}`,
    options
  );

  const lastAttendanceIndex = apiData?.data?.attendances?.length - 1;

  const userId = userDetails?.data?.user?._id;

  const filteredAssets =
    assets?.filter((asset) => asset.assignedUser?._id === userId) || [];

  const approvedLeavesCount = leaves.filter(
    (leave) => leave.status === "Approved"
  ).length;

  const currentDate = new Date();

  const upcomingHoliday = holidays
    ?.filter((holiday) => new Date(holiday?.holidayDate) > currentDate)
    ?.sort((a, b) => new Date(a?.holidayDate) - new Date(b?.holidayDate))?.[0];
  // const dateKey = getFormattedDate(
  //   i + 1,
  //   searchMonth || new Date().getMonth() + 1,
  //   searchYear || new Date().getFullYear()
  // );

  const {
    firstName,
    // lastName,
    // email,
    // role,
    // department,
    // phone,
    // gender,
    // dateOfBirth,
    image,
    // designation,
    // employeeId,
  } = userDetails?.data?.user || {};
  // const tooltip = <Tooltip id="tooltip-id">Lesley Grauer</Tooltip>;
  // const tooltip1 = <Tooltip id="tooltip-id">Richard Miles</Tooltip>;
  // const tooltip2 = <Tooltip id="tooltip-id">Loren Gatlin</Tooltip>;
  // const tooltip3 = <Tooltip id="tooltip-id">Jeffery Lalor</Tooltip>;
  // const tooltip4 = <Tooltip id="tooltip-id">Tarah Shropshire</Tooltip>;
  // const tooltip5 = <Tooltip id="tooltip-id">Catherine Manseau</Tooltip>;
  // const tooltip6 = <Tooltip id="tooltip-id">Richard Miles</Tooltip>;
  // const tooltip7 = <Tooltip id="tooltip-id">Jeffery Lalor</Tooltip>;
  // const tooltip8 = <Tooltip id="tooltip-id">Lesley Grauer</Tooltip>;
  // const tooltip9 = <Tooltip id="tooltip-id">Loren Gatlin</Tooltip>;
  // const tooltip10 = <Tooltip id="tooltip-id">Tarah Shropshire</Tooltip>;
  // const tooltip11 = <Tooltip id="tooltip-id">John Smith"</Tooltip>;
  // const tooltip12 = <Tooltip id="tooltip-id">Richard Miles</Tooltip>;
  // const tooltip13 = <Tooltip id="tooltip-id">Jeffery Lalor</Tooltip>;
  // const tooltip14 = <Tooltip id="tooltip-id">Lesley Grauer</Tooltip>;
  // const tooltip15 = <Tooltip id="tooltip-id">Tarah Shropshire</Tooltip>;
  // const tooltip16 = <Tooltip id="tooltip-id">Loren Gatlin</Tooltip>;

  // const [menu, setMenu] = useState(false);

  // const toggleMobileMenu = () => {
  //   setMenu(!menu);
  // };

  const [chartOptions] = useState({
    series: [
      {
        name: "Sales",
        data: [-50, -120, -80, -180, -80, -70, -100],
      },
      {
        name: "Purchase",
        data: [200, 250, 200, 290, 220, 300, 250],
      },
    ],
    colors: ["#FC133D", "#55CE63"],
    chart: {
      type: "bar",
      height: 210,
      stacked: true,

      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        borderRadiusApplication: "end", // "around" / "end"
        borderRadiusWhenStacked: "all", // "all"/"last"
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      min: -200,
      max: 300,
      tickAmount: 5,
    },
    xaxis: {
      categories: ["S", "M", "T", "W", "T", "F", "S"],
    },
    legend: { show: false },
    fill: {
      opacity: 1,
    },
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    marginrigth: 10,
  };

  // const settingsprojectslide = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  // };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error404 />;
  }

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid pb-0">
          {/* Leave Alert */}
          {leaveNotifications.length > 0 &&
            leaveNotifications.map((notification, index) => (
              <div className="row" key={index}>
                <div className="col-md-12">
                  <div
                    className={`alert alert-outline-${notification.type} alert-dismissible fade show mb-3`}
                  >
                    <div className="employee-alert-request d-flex align-items-center">
                      <i className="far fa-circle-question" />
                      <p className="ms-2">{notification.message}</p>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => handleDismiss(notification.id)}
                    >
                      <i className="fas fa-xmark" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          {/* /Leave Alert */}
          <div className="row">
            <div className="col-xxl-12 col-lg-12 col-md-12">
              {/* Holiday Card */}
              <div className="col-xxl-12 col-lg-12 col-md-12">
                <Holiday />
              </div>
              {/* Employee Sales Chart Data */}
              <div className="col-xxl-12 col-lg-12 col-md-12">
                <Charts />
              </div>
              <div className="row">
                {/* Employee Details */}
                <div className="col-lg-6 col-md-12">
                  <div className="card employee-welcome-card flex-fill">
                    <div className="card-body">
                      <div className="welcome-info">
                        <div className="welcome-content">
                          <h4>Welcome Back, {firstName}</h4>
                        </div>
                        <div className="welcome-img">
                          <img
                            src={image ? image : avatar2}
                            className="img-fluid"
                            alt="User"
                          />
                        </div>
                      </div>
                      <div className="welcome-btn">
                        <Link to="/profile" className="btn">
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card flex-fill h-auto">
                    <div className="card-body">
                      <div className="statistic-header">
                        <h4>Statistics</h4>
                        <div className="dropdown statistic-dropdown">
                          {/* <Link
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            to="#"
                          >
                            Today
                          </Link> */}
                          <div className="dropdown-menu dropdown-menu-end">
                            <Link to="#" className="dropdown-item">
                              Week
                            </Link>
                            <Link to="#" className="dropdown-item">
                              Month
                            </Link>
                            <Link to="#" className="dropdown-item">
                              Year
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="clock-in-info">
                        <div className="clock-in-content">
                          <p>Work Time</p>
                          <h4>
                            {minutesToHoursFormatted(
                              apiData?.data?.attendances[lastAttendanceIndex]
                                ?.totalHours
                            )}
                          </h4>
                        </div>
                        {/* <div className="clock-in-btn">
                          <Link to="#" className="btn btn-primary">
                            <img src={clockin} alt="Icon" /> Clock-In
                          </Link>
                        </div> */}
                      </div>
                      <div className="clock-in-list">
                        <ul className="nav">
                          <li>
                            <p>Remaining</p>
                            <h6>
                              {calculateRemainingTime(
                                apiData?.data?.attendances[lastAttendanceIndex]
                                  ?.totalHours
                              )}
                            </h6>
                          </li>
                          <li>
                            <p>Overtime</p>
                            <h6>
                              {minutesToHoursFormatted(
                                apiData?.data?.attendances[lastAttendanceIndex]
                                  ?.overtime
                              )}
                            </h6>
                          </li>
                          <li>
                            <p>Break</p>
                            <h6>
                              {" "}
                              {minutesToHoursFormatted(
                                apiData?.data?.attendances[lastAttendanceIndex]
                                  ?.breaks
                              )}
                            </h6>
                          </li>
                        </ul>
                      </div>
                      <div className="view-attendance">
                        <Link to="/attendance-employee">
                          View Attendance{" "}
                          <i className="fe fe-arrow-right-circle" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Employee Details */}
                {/* Attendance & Leaves */}
                <div className="col-lg-6 col-md-12">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="statistic-header">
                        <h4>Attendance &amp; Leaves</h4>
                        <div className="dropdown statistic-dropdown">
                          <Link
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            to="#"
                          >
                            2024
                          </Link>
                          <div className="dropdown-menu dropdown-menu-end">
                            <Link to="#" className="dropdown-item">
                              2025
                            </Link>
                            <Link to="#" className="dropdown-item">
                              2026
                            </Link>
                            <Link to="#" className="dropdown-item">
                              2027
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="attendance-list">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="attendance-details">
                              <h4 className="text-primary">{leaves.length}</h4>
                              <p>Total Leaves</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="attendance-details">
                              <h4 className="text-pink">
                                {approvedLeavesCount}
                              </h4>
                              <p>Leaves Taken</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="attendance-details">
                              {/* <h4 className="text-success"><TotalAbsences user={userDetails}/></h4> */}
                              <p>Leaves Absent</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="attendance-details">
                              <h4 className="text-purple">0</h4>
                              <p>Pending Approval</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="attendance-details">
                              <h4 className="text-info">214</h4>
                              <p>Working Days</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="attendance-details">
                              <h4 className="text-danger">2</h4>
                              <p>Loss of Pay</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="view-attendance">
                        <Link to="/leaves-employee">
                          Apply Leave
                          <i className="fe fe-arrow-right-circle" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Working Hours Card */}
                  {/* <div className="card flex-fill">
                    <div className="card-body">
                      <div className="statistic-header">
                        <h4>Working hours</h4>
                        <div className="dropdown statistic-dropdown">
                          <Link
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            to="#"
                          >
                            This Week
                          </Link>
                          <div className="dropdown-menu dropdown-menu-end">
                            <Link to="#" className="dropdown-item">
                              Last Week
                            </Link>
                            <Link to="#" className="dropdown-item">
                              This Month
                            </Link>
                            <Link to="#" className="dropdown-item">
                              Last 30 Days
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="working-hour-info">
                        <div id="working_chart" />
                        <Chart
                          options={chartOptions}
                          series={chartOptions.series}
                          type="bar"
                          height={210}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* /Attendance & Leaves */}
              </div>
            </div>
            {/* {Employee Assets} */}
            <div className="col-xxl-6 col-lg-12 col-md-12 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="statistic-header p-0">
                    <h4>Assets</h4>
                  </div>
                  <div className="mt-2">
                    <div>
                      <div>
                        <div className="employee-noti-content">
                          <ul className="employee-notification-list row">
                            {filteredAssets.length > 0 ? (
                              filteredAssets.map((asset, index) => (
                                <React.Fragment key={index}>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Asset Name:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.name
                                              ? asset.name
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Asset ID:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.assetId
                                              ? asset.assetId
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Purchase Date:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.purchaseDate
                                              ? new Date(
                                                  asset.purchaseDate
                                                ).toLocaleDateString()
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Assigned User:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.assignedUser
                                              ? `${
                                                  asset.assignedUser
                                                    .firstName ||
                                                  "No data Available"
                                                } ${
                                                  asset.assignedUser.lastName ||
                                                  "No data Available"
                                                }`
                                              : "No User Assigned"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Status:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.status
                                              ? asset.status
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            OS:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.specs?.os
                                              ? asset.specs.os
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            RAM:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.specs?.ram
                                              ? asset.specs.ram
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            SSD:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.specs?.ssd
                                              ? asset.specs.ssd
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            HDD:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.specs?.hdd
                                              ? asset.specs.hdd
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-6 col-md-6 col-xxl-6 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Processor :
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {" "}
                                            {asset.specs.processor &&
                                            asset.specs.processor.length > 0
                                              ? asset.specs.processor
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex align-items-center col-12 col-lg-12 col-md-12 col-xxl-12 my-2">
                                    <div className="d-flex align-items-center border py-2 px-3 rounded bg-success border-success w-100">
                                      <div className="employee-notification-icon">
                                        <p>
                                          <span className="text-white">
                                            Accessories:
                                          </span>
                                        </p>
                                      </div>
                                      <div className="employee-notification-content ms-2">
                                        <h6>
                                          <p className="text-white">
                                            {asset.accessories &&
                                            asset.accessories.length > 0
                                              ? asset.accessories
                                              : "No data Available"}
                                          </p>
                                        </h6>
                                      </div>
                                    </div>
                                  </li>
                                </React.Fragment>
                              ))
                            ) : (
                              <li className="col-12 mt-3">
                                <p className="text-center text-black">
                                  No data Available
                                </p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Employee Notifications */}
            {/* <div className="col-xxl-6 col-lg-12 col-md-12 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="statistic-header">
                    <h4>Important</h4>
                    <div className="important-notification">
                      <Link to="/activities">
                        <span className="me-1">View All</span>
                        <ArrowRightCircle size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="notification-tab">
                    <ul className="nav nav-tabs">
                      <li>
                        <Link
                          to="#"
                          className="active"
                          data-bs-toggle="tab"
                          data-bs-target="#notification_tab"
                        >
                          <i className="la la-bell" /> Notifications
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="tab"
                          data-bs-target="#schedule_tab"
                        >
                          <i className="la la-list-alt" /> Schedules
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="notification_tab">
                        <div className="employee-noti-content">
                          <ul className="employee-notification-list">
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-danger rounded-circle">
                                    HR
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Your leave request has been
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>02:10 PM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-info rounded-circle">
                                    ER
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    You’re enrolled in upcom....
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>12:40 PM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-warning rounded-circle">
                                    SM
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Your annual compliance trai
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>11:00 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="rounded-circle">
                                    <img
                                      src={avatar1}
                                      className="img-fluid rounded-circle"
                                      alt="User"
                                    />
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Jessica has requested feedba
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>10:30 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-warning rounded-circle">
                                    DT
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Gentle remainder about train
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>09:00 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-danger rounded-circle">
                                    AU
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Our HR system will be down
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>11:50 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="schedule_tab">
                        <div className="employee-noti-content">
                          <ul className="employee-notification-list">
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="rounded-circle">
                                    <img
                                      src={avatar2}
                                      className="img-fluid rounded-circle"
                                      alt="User"
                                    />
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    John has requested feedba
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>10:30 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-danger rounded-circle">
                                    HR
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Your leave request has been
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>02:10 PM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-info rounded-circle">
                                    ER
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    You’re enrolled in upcom....
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>12:40 PM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-warning rounded-circle">
                                    SM
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Your annual compliance trai
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>11:00 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-warning rounded-circle">
                                    DT
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Gentle remainder about train
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>09:00 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                            <li className="employee-notification-grid">
                              <div className="employee-notification-icon">
                                <Link to="/activities">
                                  <span className="badge-soft-danger rounded-circle">
                                    AU
                                  </span>
                                </Link>
                              </div>
                              <div className="employee-notification-content">
                                <h6>
                                  <Link to="/activities">
                                    Our HR system will be down
                                  </Link>
                                </h6>
                                <ul className="nav">
                                  <li>11:50 AM</li>
                                  <li>21 Apr 2024</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* /Employee Notifications */}
          </div>
          <div className="row">
            {/* Employee Month */}
            {/* <div className="col-xl-6 col-md-12 d-flex">
              <div className="card employee-month-card flex-fill">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-9 col-md-12">
                      <div className="employee-month-details">
                        <h4>Employee of the month</h4>
                        <p>
                          We are really proud of the difference you have made
                          which gives everybody the reason to applaud &amp;
                          appreciate
                        </p>
                      </div>
                      <div className="employee-month-content">
                        <h6>Congrats, Hanna</h6>
                        <p>UI/UX Team Lead</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="employee-month-img">
                        <img
                          src={employeeimg}
                          className="img-fluid"
                          alt="User"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* /Employee Month */}
            {/* Company Policy */}
            {/* <div className="col-xl-6 col-md-12 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-sm-8">
                      <div className="statistic-header">
                        <h4>Company Policy</h4>
                      </div>
                    </div>
                    <div className="col-sm-4 text-sm-end">
                      <div className="owl-nav company-nav nav-control" />
                    </div>
                  </div>
                  <Slider
                    {...settings}
                    className="company-slider owl-carousel owl-loaded owl-drag"
                  > */}
            {/* Company Grid */}
            {/* <div
                      className="owl-item active"
                      style={{ width: "199.667px", marginRight: "20px" }}
                    >
                      <div className="company-grid company-soft-tertiary">
                        <div className="company-top">
                          <div className="company-icon">
                            <span className="company-icon-tertiary rounded-circle">
                              HR
                            </span>
                          </div>
                          <div className="company-link">
                            <Link to="/companies">HR Policy</Link>
                          </div>
                        </div>
                        <div className="company-bottom d-flex">
                          <ul>
                            <li>Policy Name : Work policy</li>
                            <li>Updated on : Today</li>
                          </ul>
                          <div className="company-bottom-links">
                            <Link to="#">
                              <i className="la la-download" />
                            </Link>
                            <Link to="#">
                              <i className="la la-eye" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
            {/* Company Grid */}
            {/* Company Grid */}
            {/* <div
                      className="owl-item active"
                      style={{ width: "199.667px", marginRight: "20px" }}
                    >
                      <div className="company-grid company-soft-success">
                        <div className="company-top">
                          <div className="company-icon">
                            <span className="company-icon-success rounded-circle">
                              EP
                            </span>
                          </div>
                          <div className="company-link">
                            <Link to="/companies">Employer Policy</Link>
                          </div>
                        </div>
                        <div className="company-bottom d-flex">
                          <ul>
                            <li>Policy Name : Parking</li>
                            <li>Updated on : 25 Jan 2024</li>
                          </ul>
                          <div className="company-bottom-links">
                            <Link to="#">
                              <i className="la la-download" />
                            </Link>
                            <Link to="#">
                              <i className="la la-eye" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
            {/* Company Grid */}
            {/* Company Grid */}
            {/* <div
                      className="owl-item active"
                      style={{ width: "199.667px", marginRight: "20px" }}
                    >
                      <div className="company-grid company-soft-info">
                        <div className="company-top">
                          <div className="company-icon">
                            <span className="company-icon-info rounded-circle">
                              LP
                            </span>
                          </div>
                          <div className="company-link">
                            <Link to="/companies">Leave Policy</Link>
                          </div>
                        </div>
                        <div className="company-bottom d-flex">
                          <ul>
                            <li>Policy Name : Annual Leave</li>
                            <li>Updated on : 25 Jan 2023</li>
                          </ul>
                          <div className="company-bottom-links">
                            <Link to="#">
                              <i className="la la-download" />
                            </Link>
                            <Link to="#">
                              <i className="la la-eye" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
            {/* Company Grid */}
            {/* </Slider>
                </div>
              </div>
            </div> */}
            {/* /Company Policy */}
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default EmployeeDashboard;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const AddHolidayModal = ({ onSubmit, onEdit, editHoliday, mode }) => {
  const [title, setTitle] = useState("");
  const [holidayDate, setHolidayDate] = useState("");

  useEffect(() => {
    if (mode === "edit" && editHoliday) {
      setTitle(editHoliday.title);
      setHolidayDate(new Date(editHoliday.holidayDate).toISOString().slice(0, 10));
    } else {
      resetForm();
    }
  }, [mode, editHoliday]);

  const resetForm = () => {
    setTitle("");
    setHolidayDate("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure the date is properly formatted before submitting
    const parsedDate = new Date(holidayDate);
    if (isNaN(parsedDate.getTime())) {
      toast.error("Please enter a valid date.");
      return;
    }

    const payload = {
      title,
      holidayDate: parsedDate.toISOString(), // Use ISO format for consistency
    };

    try {
      if (mode === "edit") {
        await onEdit({ id: editHoliday?._id, holidayData: payload });
      } else {
        await onSubmit(payload);
      }
      resetForm();
      closeModal();
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to save holiday";
      toast.error(errorMessage);
    }
  };

  const closeModal = () => {
    const modalId = "edit_holiday";
    const modalElement = document.getElementById(modalId);
    const closeButton = modalElement?.querySelector(".btn-close");
    if (closeButton) closeButton.click();
  };

  return (
    <div id="edit_holiday" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {mode === "edit" ? "Edit Holiday" : "Add Holiday"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Holiday Title <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Holiday Date <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  required
                  value={holidayDate}
                  onChange={(e) => setHolidayDate(e.target.value)}
                />
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHolidayModal;

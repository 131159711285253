import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = (props) => {
  const location = useLocation();
  const { userDetails } = useSelector((state) => state?.user);
  const { firstName, lastName, name } = userDetails?.data?.user || {};
  const displayName =
    firstName && lastName ? `${firstName} ${lastName}` : name ? name : "Admin";
  let addButton = null;
  if (
    location.pathname === "/admin-attendance" ||
    location.pathname === "/attendance-employee" ||
    location.pathname === "/create-sale" ||
    location.pathname === "/edit-sale" ||
    location.pathname === "/user-dashboard" ||
    location.pathname === "/profile"
  ) {
    addButton = (
      <div className="row">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
            <li style={{ padding: "0px 10px" }}>/</li>
            <li>{displayName}</li>
          </ul>
        </div>
      </div>
    );
  } else if (
    location.pathname === "/employees" ||
    location.pathname === "/employees-list"
  ) {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
            <li style={{ padding: "0px 10px" }}>/</li>
            <li>{displayName}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <Link
            to="#"
            className="btn add-btn"
            data-bs-toggle="modal"
            data-bs-target={props.modal}
          >
            <i className="fa fa-plus" /> {props.name}
          </Link>
          <div className="view-icons">
            <Link
              to={props.Linkname}
              className={`grid-view btn btn-link mx-2 ${location.pathname === props.Linkname ?'active' : ''}`}
            >
              <i className="fa fa-th" />
            </Link>
            <Link to={props.Linkname1} className={`list-view btn btn-link ${location.pathname === props.Linkname1 ?'active' : ''}`}>
              <i className="fa fa-bars" />
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (
    location.pathname === "/sales" ||
    location.pathname === "/calls"
  ) {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
            <li style={{ padding: "0px 10px" }}>/</li>
            <li>{displayName}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <Link
            to={props.link}
            className="btn add-btn"
            data-bs-target={props.modal}
          >
            <i className="fa fa-plus" />
            {props.name}
          </Link>
        </div>
      </div>
    );
  }
  else {
    addButton = (
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{props.maintitle}</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              {props.title}
            </li>
            <li className="breadcrumb-item active">{props.subtitle}</li>
            <li style={{ padding: "0px 10px" }}>/</li>
            <li>{displayName}</li>
          </ul>
        </div>
        {props?.name && (
          <div className={props.modalClass}>
            <Link
              to="#"
              className="btn add-btn"
              data-bs-toggle="modal"
              data-bs-target={props.modal}
            >
              <i className="fa fa-plus" />
              {props.name}
            </Link>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      <div className="page-header">{addButton}</div>
    </>
  );
};

export default Breadcrumbs;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Pagination } from "antd";
import axios from "axios";
import {
  fetchEmployees,
  deleteEmployee,
  updateEmployee,
} from "../../../store/slices/EmployeeSlice";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { Avatar_02 } from "../../../Routes/ImagePath";
import { toast } from "react-toastify";
import Loading from "../../../hooks/utils/Loading/Loading";

const EmployeeList = () => {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state?.employee?.employees);
  const total = useSelector((state) => state?.employee?.total); // Total employees count from Redux
  const role = useSelector((state) => state?.auth?.userRole);
  const token = useSelector((state) => state?.auth?.userInfo?.userToken);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [fetchEmployeesReq, setFetchEmployeesReq] = useState(false);

  useEffect(() => {
    dispatch(fetchEmployees({ page: currentPage, limit: pageSize }));
  }, [dispatch, currentPage, pageSize, fetchEmployeesReq]);

  useEffect(() => {
    if (Array.isArray(employees)) {
      setFilteredEmployees(employees);
    }
  }, [employees]);

  const handleFilterChange = ({ employeeId, employeeName, department }) => {
    const filtered = employees.filter((employee) => {
      const matchesId = employeeId
        ? employee.employeeId?.toString().includes(employeeId) || false
        : true;
      const matchesName = employeeName
        ? (
          (employee.firstName?.toLowerCase() || "") +
          (employee.lastName?.toLowerCase() || "")
        ).includes(employeeName.toLowerCase())
        : true;
      const matchesDepartment =
        !department || department.label === "All"
          ? true
          : employee?.department &&
          employee?.department?._id === department?.value;
      return matchesId && matchesName && matchesDepartment;
    });

    setFilteredEmployees(filtered);
  };

  const startTimeOptions = [
    { value: 16, label: "04:00 PM" },
    { value: 17, label: "05:00 PM" },
    { value: 18, label: "06:00 PM" },
    { value: 19, label: "07:00 PM" },
    { value: 20, label: "08:00 PM" },
    { value: 21, label: "09:00 PM" },
  ];

  const endTimeOptions = [
    { value: 3, label: "03:00 AM" },
    { value: 4, label: "04:00 AM" },
    { value: 5, label: "05:00 AM" },
    { value: 6, label: "06:00 AM" },
    { value: 7, label: "07:00 AM" },
    { value: 8, label: "08:00 AM" },
  ];

  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    if (employeeToDelete) {
      dispatch(deleteEmployee(employeeToDelete._id));
    }
    setShowDeleteModal(false);
    setEmployeeToDelete(null);
  };

  const handleStatusChange = (employee, status) => {
    dispatch(updateEmployee({ id: employee._id, status }))
      .unwrap()
      .then(() => {
        dispatch(fetchEmployees({ page: currentPage, limit: pageSize }));
        const message =
          status === "active"
            ? `${employee.firstName} ${employee.lastName} has been enabled.`
            : `${employee.firstName} ${employee.lastName} has been disabled.`;
        toast.success(message);
      })
      .catch((error) => {
        console.error("Error updating employee status:", error);
        toast.error("Failed to update status. Please try again.");
      });
  };

  const handleChange = async (option, value, userId) => {
    const data = new FormData();
    let keyValue = value;

    if (option === "startTime" || option === "endTime") {
      const time = new Date(new Date().setHours(value, 0, 0, 0));
      keyValue = time;
    }

    data.append(option, keyValue);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/api/users/${userId}`,
        data,
        config
      );

      if (response.status === 200) {
        toast.success("Updated successfully!");
        setFetchEmployeesReq((prev) => !prev);
      }
    } catch (error) {
      console.error(
        `Error updating`,
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to update. Please try again.");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to={`/employeeprofile/${record._id}`} className="avatar">
            <img alt="" src={record.image || Avatar_02} />
          </Link>
          <Link to={`/employeeprofile/${record._id}`}>
            {text}{" "}
            <span>
              {record.lastName} ({record.role})
            </span>
          </Link>
        </span>
      ),
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      sorter: (a, b) => a.employeeId?.localeCompare(b.employeeId),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Mobile",
      dataIndex: "phone",
    },
    {
      title: "Join Date",
      dataIndex: "dateOfJoining",
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.dateOfJoining) - new Date(b.dateOfJoining),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text, record) => (
        <div className="dropdown action-label text-start">
          <span
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "admin"
                  ? "far fa-dot-circle text-purple"
                  : text === "employee"
                    ? "far fa-dot-circle text-info"
                    : text === "manager"
                      ? "far fa-dot-circle text-success"
                      : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </span>
          {role === "admin" ? (
            <div className="dropdown-menu dropdown-menu-right">
              <span
                className="dropdown-item"
                onClick={() => handleChange("role", "admin", record._id)}
              >
                <i className="far fa-dot-circle text-success" /> Admin
              </span>
              <span
                className="dropdown-item"
                onClick={() => handleChange("role", "employee", record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> Employee
              </span>
              <span
                className="dropdown-item"
                onClick={() => handleChange("role", "manager", record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> Manager
              </span>
              <span
                className="dropdown-item"
                onClick={() => handleChange("role", "hr", record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> HR
              </span>
              <span
                className="dropdown-item"
                onClick={() => handleChange("role", "supervisor", record._id)}
              >
                <i className="far fa-dot-circle text-danger" /> Supervisor
              </span>
            </div>
          ) : (
            <div className="dropdown-menu dropdown-menu-right">
              <span className="dropdown-item">{""}</span>
            </div>
          )}
        </div>
      ),
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Weekly Off Days",
      dataIndex: "weeklyOffDays",
      render: (text, record) => (
        <div className="dropdown action-label text-start">
          <span
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === 1
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-info"
              }
            />{" "}
            {text || "N/A"}
          </span>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              onClick={() => handleChange('weeklyOffDays', 1, record._id)}
            >
              <i className="far fa-dot-circle text-success" /> 1
            </span>
            <span
              className="dropdown-item"
              onClick={() => handleChange('weeklyOffDays', 2, record._id)}
            >
              <i className="far fa-dot-circle text-info" /> 2
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Shift Start Time",
      dataIndex: "startTime",
      render: (text, record) => {
        const defaultOption = startTimeOptions.find(
          (option) => option.value === new Date(record.startTime).getHours()
        ) || { label: "Select Time" };

        return (
          <div className="dropdown action-label text-start">
            <span
              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i
                className={`far fa-dot-circle ${defaultOption.value ? "text-success" : "text-danger"
                  }`}
              />{" "}
              {defaultOption.label}
            </span>
            <div className="dropdown-menu dropdown-menu-right">
              {startTimeOptions.map((v) => (
                <span
                  className="dropdown-item"
                  onClick={() => handleChange('startTime', v.value, record._id)}
                >
                  <i className="far fa-dot-circle text-success" /> {v.label}
                </span>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      title: "Shift End Time",
      dataIndex: "endTime",
      render: (text, record) => {
        const defaultOption = endTimeOptions.find(
          (option) => option.value === new Date(record.endTime).getHours()
        ) || { label: "Select Time" };

        return (
          <div className="dropdown action-label text-start">
            <span
              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i
                className={`far fa-dot-circle ${defaultOption.value ? "text-success" : "text-danger"
                  }`}
              />{" "}
              {defaultOption.label}
            </span>
            <div className="dropdown-menu dropdown-menu-right">
              {endTimeOptions.map((v) => (
                <span
                  className="dropdown-item"
                  onClick={() => handleChange('endTime', v.value, record._id)}
                >
                  <i className="far fa-dot-circle text-success" /> {v.label}
                </span>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="dropdown profile-action">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {record.status !== "active" && (
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => handleStatusChange(record, "active")}
              >
                <i className="fa fa-check m-r-5" />
                Enable
              </Link>
            )}
            {record.status !== "disabled" && (
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => handleStatusChange(record, "disabled")}
              >
                <i className="fa fa-times m-r-5" />
                Disable
              </Link>
            )}
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleDeleteClick(record)}
            >
              <i className="fa fa-trash m-r-5" />
              Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setLoading(true);
    setPageSize(pageSize);
    setCurrentPage(page);

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employee"
            title="Main"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            modalClass="col-auto float-end ms-auto"
          />
          <EmployeeListFilter onFilterChange={handleFilterChange} />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive mb-3">
                <SearchBox />

                {loading ? (
                  <Loading />
                ) : (
                  <Table
                    className="table-hover"
                    columns={columns}
                    dataSource={filteredEmployees}
                    rowKey={(record) => record._id}
                    pagination={false}
                    rowClassName={(record) =>
                      record.status === "disabled"
                        ? `text-light bg-secondary`
                        : ""
                    }
                  />
                )}
                {/* Pagination Component */}
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={total}
                      onChange={handlePageChange}
                      showSizeChanger
                      pageSizeOptions={["10", "20", "50", "100"]}
                      showQuickJumper
                      showTotal={(total) => `Total ${total} employees`}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          return <span>Previous</span>;
                        }
                        if (type === "next") {
                          return <span>Next</span>;
                        }
                        if (type === "jump-prev" || type === "jump-next") {
                          return <span>...</span>;
                        }
                        return originalElement;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AllEmployeeAddPopup />
        <DeleteModal
          show={showDeleteModal}
          onDeleteLeave={handleDeleteConfirm}
          closeModal={() => setShowDeleteModal(false)}
          entityName="employee"
        />
      </div>
    </div>
  );
};

export default EmployeeList;

import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import useFetch from "../../../../hooks/api/fetchHook.jsx";
import Breadcrumbs from "../../../../components/Breadcrumbs.jsx";
import DeleteModal from "../../../../components/modelpopup/DeleteModal.jsx";
import UserTable from "./UserTable.jsx";

const UserReport = () => {
  const { userToken } = useSelector((state) => state?.auth);
  const [user, setUser] = useState("");
  const [date, setDate] = useState(new Date());

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const { apiData } = useFetch(
    `${process.env.REACT_APP_BASE_URL}/api/users/?page=1&limit=1000`,
    options
  );

  const users = apiData?.data?.users
  ? apiData?.data?.users.filter(user => user?.department?.type === 'sales')
      .map((user) =>
      ({
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
      }
      )
    )
    : [];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "50px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#ff9b44" : provided.borderColor,
      paddingTop: "10px",
      "&:hover": {
        borderColor: "#ff9b44",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Report"
            title="Main"
            subtitle="Report"
            modal="#"
            modalClass="col-auto float-end ms-auto"
          />

          <div className="row filter-row">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Sales Associates"
                  onChange={(user) => setUser(user.value)}
                  // defaultValue={users[0]}
                  options={users}
                  styles={customStyles}
                />
                <label className="focus-label">User</label>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div
                className={`input-block mb-3 form-focus focused`}
              >
                <div className="cal-icon focused">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={date}
                    onSelect={(date) => setDate(date)}
                    onChange={(date) => setDate(date)}
                    // maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <label className="focus-label">Date</label>
              </div>
            </div>
          </div>

          <UserTable
            user={user}
            date={date}
          />
        </div>
      </div>
      <DeleteModal Name="Delete Estimate" />
    </>
  );
};

export default UserReport;

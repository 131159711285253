import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLeaves,
  approveLeave,
  rejectLeave,
  deleteLeave,
} from "../../../store/slices/LeaveSlice";
import Select from "react-select";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Error404 from "../../../hooks/utils/Error/Error";
import Loading from "../../../hooks/utils/Loading/Loading";

const AdminLeave = () => {
  const dispatch = useDispatch();
  const { leaves, status } = useSelector((state) => state.leave);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [filterStatus, setFilterStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredLeaves, setFilteredLeaves] = useState([]);

  useEffect(() => {
    dispatch(fetchAllLeaves());
  }, [dispatch]);

  useEffect(() => {
    const filtered = leaves.filter((leave) => {
      if (filterStatus === "All") return true;
      return leave.status === filterStatus;
    });
    setFilteredLeaves(filtered);
  }, [leaves, filterStatus]);

  const handleApproveLeave = (id) => {
    dispatch(approveLeave({ id, approvedBy: userInfo.id }));
    dispatch(fetchAllLeaves());
  };

  const handleRejectLeave = (id) => {
    dispatch(rejectLeave({ id, rejectedBy: userInfo.id }));
    dispatch(fetchAllLeaves());
  };

  const handleDeleteLeaveClick = (id) => {
    setSelectedLeave(id);
  };

  const confirmDeleteLeave = () => {
    if (!selectedLeave) return
      dispatch(deleteLeave(selectedLeave));
      setSelectedLeave(null);
  };

  const closeDeleteModal = () => {
    setSelectedLeave(null);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["userId", "firstName"],
      render: (text, record) =>
        `${record?.userId?.firstName} ${record?.userId?.lastName}`,
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
    },
    {
      title: "From",
      dataIndex: "startDate",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "To",
      dataIndex: "endDate",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-start">
          <a
            href="/****"
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "New"
                  ? "far fa-dot-circle text-purple"
                  : text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <a
              href="/****"
              className="dropdown-item"
              onClick={() => handleApproveLeave(record._id)}
            >
              <i className="far fa-dot-circle text-success" /> Approve
            </a>
            <a
              href="/****"
              className="dropdown-item"
              onClick={() => handleRejectLeave(record._id)}
            >
              <i className="far fa-dot-circle text-danger" /> Reject
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-center text-danger">
          <a
            href="/****"
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#delete"
            onClick={() => handleDeleteLeaveClick(record._id)}
          >
            <i className="fa fa-trash m-r-5 text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const statusOptions = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leaves"
            title="Main"
            subtitle="Employees"
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row mb-3">
            <div className="col-md-3">
              <Select
                options={statusOptions}
                defaultValue={statusOptions[0]}
                onChange={(option) => setFilterStatus(option.value)}
                placeholder="Filter by Status"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive mb-3">
                <Table
                  columns={columns}
                  dataSource={filteredLeaves}
                  rowKey={(record) => record._id}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: filteredLeaves.length,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                  }}
                  locale={{
                    emptyText:
                      status.fetchAllLeaves === "loading" ? (
                        <div>
                          <Loading /> {/* Shows loading spinner in table */}
                        </div>
                      ) : status.fetchAllLeaves === "failed" ? (
                        <div className="text-center text-danger">
                          <Error404 />
                        </div>
                      ) : (
                        "No Data"
                      ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        onDeleteLeave={confirmDeleteLeave}
        closeModal={closeDeleteModal}
      />
    </>
  );
};

export default AdminLeave;

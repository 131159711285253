// src/components/AssetListFilter.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const AssetListFilter = ({ onFilterChange }) => {
  const users = useSelector((state) => state?.employee?.employees || []);
  const [assetId, setAssetId] = useState("");
  const [assetName, setAssetName] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [status, setStatus] = useState("");

  const handleFilterChange = () => {
    onFilterChange({
      assetId,
      assetName,
      assignedUser: selectedUser,
      status,
    });
  };
  
  useEffect(() => {
    handleFilterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId, assetName, selectedUser, status]);

  const userOptions = users.map((user) => ({
    value: user?._id,
    label: `${user?.firstName || "No FirstName"} ${
      user?.lastName || "No LastName"
    }`,
  }));

  const statusOptions = [
    { value: "", label: "All" },
    { value: "Available", label: "Available" },
    { value: "Assigned", label: "Assigned" },
    { value: "In Repair", label: "In Repair" },
    { value: "Retired", label: "Retired" },
  ];

  // Custom styles for Select component to match input fields
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "44px", // Adjust height to match input fields
      minHeight: "44px",
      borderColor: "#ced4da", // Match border color with input fields
      boxShadow: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "38px", // Match height with control
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "38px", // Match height with control
      padding: "0 8px",
    }),
  };

  return (
    <div className="row filter-row">
      <div className="col-sm-6 col-md-3 mb-3">
        <input
          type="text"
          className="form-control floating"
          placeholder="Asset ID"
          value={assetId}
          onChange={(e) => setAssetId(e.target.value)}
        />
      </div>

      <div className="col-sm-6 col-md-3 mb-3">
        <input
          type="text"
          className="form-control floating"
          placeholder="Asset Name"
          value={assetName}
          onChange={(e) => setAssetName(e.target.value)}
        />
      </div>

      <div className="col-sm-6 col-md-3 mb-3">
        <Select
          options={[{ value: null, label: "All" }, ...userOptions]}
          placeholder="Select User"
          onChange={(option) => setSelectedUser(option)}
          styles={customStyles}
        />
      </div>

      <div className="col-sm-6 col-md-3 mb-3">
        <Select
          options={statusOptions}
          placeholder="Select Status"
          onChange={(option) => setStatus(option.value)}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default AssetListFilter;

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import AddHolidayModal from "../../../../components/modelpopup/AddHolidayModal";
import SearchBox from "../../../../components/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../../../../hooks/utils/Loading/Loading";
import Error404 from "../../../../hooks/utils/Error/Error";
import {
  addHoliday,
  fetchHolidays,
  removeHoliday,
  updateHoliday,
} from "../../../../store/slices/HolidaySlice";

const HolidayManagement = () => {
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [mode, setMode] = useState();
  const dispatch = useDispatch();
  const { holidays, status, error } = useSelector((state) => state.holidays);
  const role = useSelector(
    (state) => state?.user?.userDetails?.data?.user?.role
  );

  useEffect(() => {
    dispatch(fetchHolidays());
  }, [dispatch]);

  // Handle adding a new holiday
  const handleAddHoliday = async (holidayData) => {
    try {
      await dispatch(addHoliday(holidayData)).unwrap();
      toast.success("Holiday added successfully");
      // Optionally refetch holidays to ensure state is up-to-date
      dispatch(fetchHolidays());
    } catch (error) {
      const errorMessage = error ? error : "Failed to add holiday";
      toast.error(errorMessage);
    }
  };

  // Handle editing an existing holiday
  const handleEditHoliday = (holiday) => {
    setSelectedHoliday(holiday);
    setMode("edit");
  };

  // Handle updating a holiday
  const handleUpdateHoliday = async (holidayData) => {
    try {
      await dispatch(
        updateHoliday({ id: selectedHoliday._id, data: holidayData })
      ).unwrap();
      toast.success("Holiday updated successfully");
      setSelectedHoliday(null);
      setMode(null);
    } catch (error) {
      const errorMessage = error ? error : "Failed to update holiday";
      toast.error(errorMessage);
    }
  };

  // Handle deleting a holiday
  const handleDeleteHoliday = (holiday) => {
    setSelectedHoliday(holiday);
  };

  const handleDelete = async () => {
    if (selectedHoliday) {
      try {
        await dispatch(removeHoliday(selectedHoliday._id)).unwrap();
        toast.success("Holiday deleted successfully");
      } catch (error) {
        const errorMessage = error ? error : "Failed to delete holiday";
        toast.error(errorMessage);
      }
    }
  };

  // Define table columns
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
      width: "5%",
    },
    {
      title: "Holiday Title",
      dataIndex: "title",
      width: "20%",
    },
    {
      title: "Holiday Date",
      dataIndex: "holidayDate",
      render: (text) => new Date(text).toLocaleDateString(),
      width: "20%",
    },
    {
      title: "Day",
      dataIndex: "day",
      width: "15%",
    },
    {
      title: role !== "employee" ? "Action" : null,
      className: "text-end",
      render: (_, record) =>
        role !== "employee" ? (
          <div className="dropdown dropdown-action text-end">
            <Link
              to="#"
              className="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <i className="material-icons">more_vert</i>
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#edit_holiday"
                onClick={() => handleEditHoliday(record)}
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </Link>
              <Link
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#delete"
                onClick={() => handleDeleteHoliday(record)}
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </Link>
            </div>
          </div>
        ) : null,
      width: "10%",
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Holidays"
            title="Dashboard"
            subtitle="Holidays"
            modal="#edit_holiday"
            name={role !== "employee" ? "Add Holiday" : null}
            modalClass="col-auto float-end ms-auto"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive mb-3">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={holidays}
                  className="table-striped"
                  rowKey={(record) => record?._id}
                  locale={{
                    emptyText:
                      status === "loading" ? (
                        <Loading />
                      ) : error ? (
                        <div className="text-center text-danger">
                          <Error404 />
                        </div>
                      ) : (
                        "No Data"
                      ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddHolidayModal
        onSubmit={handleAddHoliday}
        onEdit={handleUpdateHoliday}
        editHoliday={selectedHoliday}
        mode={mode}
      />
      <DeleteModal Name="Delete Holiday" onDeleteLeave={handleDelete} />
    </div>
  );
};

export default HolidayManagement;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import AppContainer from "../Appcontainer";
import { persistor, store } from "../../store/index";
import { Provider } from "react-redux";
import Login from "../../views/pages/Authentication/Login";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// Component to scroll to the top of the page on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

// Allowed IP addresses
const allowedIPs = [
  '202.59.90.146',
  '119.73.101.85',
  '192.168.18.42',
  '2001:4860:7:222::ff',
  '36.50.12.48',
  '119.73.101.111'
];

const AppRouter = () => {
  const [userIP, setUserIP] = useState(null);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    // Fetch user's public IP address
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        setUserIP(ip);
        // Check if the IP is allowed
        if (allowedIPs.includes(ip)) {
          setIsAllowed(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching IP", error);
      });
  }, []);

  // Render "Access Denied" if the user's IP is not allowed
  if (!isAllowed && userIP !== null) {
    return <h1>Access Denied: You are not connected to the allowed network.</h1>;
  }

  // Render the application if the user is allowed
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/*" element={<AppContainer />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <ToastContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default AppRouter;